import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ShowMore from "./ShowMore";;
import { Trans } from "react-i18next";
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";
import "../../MyAccount.css"

const Summary = ({ resumeId, UpdateResumeData,resume,setUpdateResumeDataData }) => {
  const { user, setUserDetails } = useContext(UserContext);
  const [summary, setSummary] = useState("");
  useEffect(() => {
    if (resume) {
      setSummary(resume?.resume?.professionalSummary?.summary);
    }
  }, [resume]);
  return (
    <>
      {/* <span>{Summary && Summary != "" ? Summary : "Not Spectfied"}</span> */}
      {summary && summary != "" ? <ShowMore description={summary} charecterLength={1500} /> : (
        <>
          <span className="d-flex align-items-center justify-content-center details">
            <Trans i18nKey="noSummaryDetails" defaults="No Summary Details" />
          </span>
        </>
      )}
    </>
  );
};

export default Summary;
