import React, {
  useState,
  useEffect,
  useContext,
  Suspense,
  useRef,
} from "react";
import Loading from "../../../../Loder";
import Education from "./Education";
import References from "./References";
import Experience from "./Experience";
import Licenses from "./Licenses";
import SummarySession from "./SummarySession";
import SkillsSession from "./SkillsSession";
import CompensationsSession from "./CompensationsSession";
import { v4 as uuidv4 } from "uuid";
import OtherInformationSession from "./OtherInformationSession";

const ProfileDetailsFields = ({
  profileLayoutData,
  resumeId,
  resumeData,
  setUpdateResumeDataData,
  scrollRef,
  setscrollRef
}) => {
  let arr = [
    "References",
    "Education",
    "Licenses",
    "Experience",
    "Summary",
    "Skills",
    "Compensations",
    "Other Information",
  ];
  let arr2 = [];
  const [layout, setLayout] = useState([]);
  useEffect(() => {
    if (profileLayoutData?.layouts) {
      setLayout(profileLayoutData.layouts);
    }
  }, [profileLayoutData?.layouts]);

  profileLayoutData?.completeProfile?.sections?.map((section, index) => {
    if (arr.includes(section?.sectionCode)) {
      arr2.push(index);
    }
  });
  layout.forEach((subarray, index) => {
    layout[index] = subarray?.filter((item) => arr2.includes(item));
  });

  const finalLayout = layout.filter((subarray) => subarray.length > 0);

  return (
    <>
      {finalLayout?.map((layout, index) => {
        let colNumber = "";
        if (layout?.length > 0) {
          colNumber = 12 / layout?.length;
          if (colNumber < 4) {
            colNumber = 4;
          }
        }
        return (
          <div key={uuidv4()}>
            <div className="row my-1" key={uuidv4()}>
              {layout.map((item, index1) => {
                let cardAlignCss = "";
                if (layout?.length > 1) {
                  if (index1 == layout?.length - 1) {
                    cardAlignCss = " ps-lg-1 pb-md-2 pb-lg-0";
                  } else {
                    if (index1 != 0) {
                      cardAlignCss = " px-lg-1 pb-md-2 pb-lg-0";
                    } else {
                      cardAlignCss = " pe-lg-1 pb-md-2 pb-lg-0";
                    }
                  }
                }
                return (
                  <div
                    className={"col-md-12 col-lg-" + colNumber + cardAlignCss}
                    key={uuidv4()}
                  >
                    {profileLayoutData?.completeProfile?.sections[item]
                      .sectionCode === "References" ? (
                      <Suspense
                        fallback={
                          <>
                            <Loading rows={0} />
                          </>
                        }
                      >
                        <References
                          referenceRows={
                            profileLayoutData?.completeProfile?.sections[item]
                              .rows
                          }
                          resumeId={resumeId}
                          standardFieldsList={
                            profileLayoutData?.completeProfile?.sections[item]
                          }
                          status={resumeData?.resumeDetails?.status}
                          resumeData={resumeData}
                          setUpdateResumeDataData={setUpdateResumeDataData}
                        />
                      </Suspense>
                    ) : null}
                    {profileLayoutData?.completeProfile?.sections[item]
                      .sectionCode === "Education" ? (
                      <Suspense
                        fallback={
                          <>
                            <Loading rows={0} />
                          </>
                        }
                      >
                        <Education
                          educationRows={
                            profileLayoutData?.completeProfile?.sections[item]
                              .rows
                          }
                          resumeId={resumeId}
                          standardFieldsList={
                            profileLayoutData?.completeProfile?.sections[item]
                          }
                          status={resumeData?.resumeDetails?.status}
                          resumeData={resumeData}
                          setUpdateResumeDataData={setUpdateResumeDataData}
                          scrollRef={scrollRef}
                          setscrollRef={setscrollRef}
                        />
                      </Suspense>
                    ) : null}
                    {profileLayoutData?.completeProfile?.sections[item]
                      .sectionCode === "Licenses" ? (
                      <Suspense
                        fallback={
                          <>
                            <Loading rows={0} />
                          </>
                        }
                      >
                        <Licenses
                          licenseRows={
                            profileLayoutData?.completeProfile?.sections[item]
                              .rows
                          }
                          resumeId={resumeId}
                          standardFieldsList={
                            profileLayoutData?.completeProfile?.sections[item]
                          }
                          status={resumeData?.resumeDetails?.status}
                          resumeData={resumeData}
                          setUpdateResumeDataData={setUpdateResumeDataData}
                        />
                      </Suspense>
                    ) : null}
                    {profileLayoutData?.completeProfile?.sections[item]
                      .sectionCode === "Experience" ? (
                      <Suspense
                        fallback={
                          <>
                            <Loading rows={0} />
                          </>
                        }
                      >
                        <Experience
                          experienceRow={
                            profileLayoutData?.completeProfile?.sections[item]
                              .rows
                          }
                          resumeId={resumeId}
                          standardFieldsList={
                            profileLayoutData?.completeProfile?.sections[item]
                          }
                          status={resumeData?.resumeDetails?.status}
                          resumeData={resumeData}
                          setUpdateResumeDataData={setUpdateResumeDataData}
                          scrollRef={scrollRef}
                          setscrollRef={setscrollRef}
                        />
                      </Suspense>
                    ) : null}

                    {profileLayoutData?.completeProfile?.sections[item]
                      .sectionCode === "Summary" ? (
                      <Suspense
                        fallback={
                          <>
                            <Loading rows={0} />
                          </>
                        }
                      >
                        <SummarySession
                          resumeId={resumeId}
                          standardFieldsList={
                            profileLayoutData?.completeProfile?.sections[item]
                          }
                          status={resumeData?.resumeDetails?.status}
                          resumeData={resumeData}
                          setUpdateResumeDataData={setUpdateResumeDataData}
                          scrollRef={scrollRef}
                          setscrollRef={setscrollRef}
                        />
                      </Suspense>
                    ) : null}

                    {profileLayoutData?.completeProfile?.sections[item]
                      .sectionCode === "Skills" ? (
                      <Suspense
                        fallback={
                          <>
                            <Loading rows={0} />
                          </>
                        }
                      >
                        <SkillsSession
                          resumeId={resumeId}
                          standardFieldsList={
                            profileLayoutData?.completeProfile?.sections[item]
                          }
                          status={resumeData?.resumeDetails?.status}
                          resumeData={resumeData}
                          setUpdateResumeDataData={setUpdateResumeDataData}
                          scrollRef={scrollRef}
                          setscrollRef={setscrollRef}
                        />
                      </Suspense>
                    ) : null}

                    {profileLayoutData?.completeProfile?.sections[item]
                      .sectionCode === "Compensations" ? (
                      <Suspense
                        fallback={
                          <>
                            <Loading rows={0} />
                          </>
                        }
                      >
                        <CompensationsSession
                          resumeId={resumeId}
                          standardFieldsList={
                            profileLayoutData?.completeProfile?.sections[item]
                          }
                          status={resumeData?.resumeDetails?.status}
                          resumeData={resumeData}
                          setUpdateResumeDataData={setUpdateResumeDataData}
                        />
                      </Suspense>
                    ) : null}

                    {profileLayoutData?.completeProfile?.sections[item]
                      .sectionCode === "Other Information" ? (
                      <Suspense
                        fallback={
                          <>
                            <Loading rows={0} />
                          </>
                        }
                      >
                        <OtherInformationSession
                          resumeId={resumeId}
                          standardFieldsList={
                            profileLayoutData?.completeProfile?.sections[item]
                          }
                          status={resumeData?.resumeDetails?.status}
                          resumeData={resumeData}
                          setUpdateResumeDataData={setUpdateResumeDataData}
                        />
                      </Suspense>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProfileDetailsFields;
