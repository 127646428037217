import React, { useEffect, useState, useContext } from "react";
// import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ProfileStandard from "../ProfileStandard";
import { Modal } from "rsuite";
import SkillsModal from "../forms/SkillsModal";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";
import SkillsFormField from "../../../profile/form/SkillsFormField";
import Skills from "./Skills";
const SkillsSession = ({
  standardFieldsList,
  resumeId,
  resumeData,
  status,
  setUpdateResumeDataData,
  scrollRef,
  setscrollRef,
}) => {
  const { post: updateResumeDetails, isLoading: UpdateResumeDataDataLoading } =
    usePost("/user/Updateresumedeatails");

  const [afterUpdateResumeData, setAfterUpdateResumeData] = useState({});
  const { user, setUserDetails } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
  } = useForm();
  const handleSkillsOpen = () => {
    setOpen(true);
    setAfterUpdateResumeData({});
  };
  const handleSkillsClose = () => {
    clearErrors();
    setOpen(false);
    setscrollRef(null);
  };
  useEffect(() => {
    if (scrollRef == "skills") {
      handleSkillsOpen();
    }
  }, [scrollRef]);
  const handleSkillsSubmit = (data) => {
    updateResumeDetails({
      target: "skills",
      resumeID: resumeId,
      user: user,
      companyID: resumeData?.companyID,
      clientID: resumeData?.clientID,
      getValues: {
        ...data,
        sectionName: standardFieldsList?.sectionName
          ? standardFieldsList?.sectionName
          : "Skills",
      },
      type: "Profile Edited",
    })
      .then((res) => {
        if (res) {
          setUpdateResumeDataData(res);
          handleSkillsClose();
        }
      })
      .catch((error) => {
        console.error("Error while fetching Resume:", error);
      });
  };
  useEffect(() => {
    if (scrollRef == "skills") {
      handleSkillsOpen();
    }
  }, [scrollRef]);

  return (
    <>
      <div className="card h-100">
        <div className="card-body">
          <div className="row">
            <div className="col-10 d-flex  align-items-center">
              <h6 className="text-primary m-0">
                <Trans i18nKey="skills" defaults="Skills" />
              </h6>
            </div>
            {/* {status != "MERGED" &&
            status != "DISQUALIFIED" &&
            status != "DELETED" ? (
              <> */}
            <div className="col-2 justify-content-end d-flex">
              {/* {standardFieldsList?.editable ? ( */}
              <>
                <span
                  className="IconArea actionIcon actionIcon-sm"
                  onClick={handleSkillsOpen}
                >
                  <FontAwesomeIcon className="mx-2" icon={faEdit} />
                </span>
              </>
              {/* ) : null} */}
            </div>
            {/* </>
            ) : (
              <></>
            )} */}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                {/* {standardFieldsList?.rows?.map((sections, index) => {
                  let colNumber = 12;
                  if (sections?.columns.length > 0) {
                    colNumber = 12 / sections?.columns.length;
                  }
                  return (
                    <div className="row w-100 mb-3" key={uuidv4()}>
                      {sections?.columns?.map((item, index) => {
                        return (
                          <div className={"col-" + colNumber} key={uuidv4()}>
                            <ProfileStandard
                              item={item}
                              resumeID={resumeId}
                              UpdateResumeData={afterUpdateResumeData}
                              resumeData={resumeData}
                              setUpdateResumeDataData={setUpdateResumeDataData}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })} */}
                <Skills resume={resumeData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size={"lg"} open={open} onClose={handleSkillsClose}>
        <Modal.Header>
          <Modal.Title>
            <Trans i18nKey="skills" defaults="Skills" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row w-100">
            <div className="col-sm-12 col-md-12">
              {/* <SkillsModal
                itemObj={standardFieldsList?.rows}
                resumeId={resumeId}
                control={control}
                register={register}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
                handleSubmit={handleSubmit}
              /> */}
              <SkillsFormField
                // skillsData={[]}
                skillsData={
                  resumeData?.resume?.professionalQualification?.skills
                    ? resumeData?.resume?.professionalQualification?.skills
                    : []
                }
                // skillsData={getValues()?.skills}
                control={control}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                register={register}
                handleSubmit={handleSubmit}
                type="customLayout"
                from="BasicDetails"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row pt-2">
            <div className="col-md-12 col-sm-12 d-flex justify-content-between">
              <span>
                <button
                  className="btn btn-sm btn-light"
                  onClick={handleSkillsClose}
                >
                  <Trans i18nKey="cancel" defaults="Cancel" />
                </button>
              </span>
              <span>
                <button
                  className="btn btn-sm btn-primary"
                  disabled={UpdateResumeDataDataLoading}
                  onClick={handleSubmit(handleSkillsSubmit)}
                >
                  {UpdateResumeDataDataLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  <span className="px-1">
                    <Trans i18nKey="save" defaults="Save" />
                  </span>
                </button>
              </span>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SkillsSession;