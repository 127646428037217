import { useLoaderData } from "react-router-dom";
import "./CareersJobdeatails.css";
import React, { useEffect, useState, useRef } from "react";
import CareersReferFriend from "./CareersReferFriend";
import parse from "html-react-parser";
import ShowMore from "./Showmore";
// import { convert } from "html-to-text";
import usePost from "../../../customhooks/UsePost";
import { TagGroup, Tag } from "rsuite";
import SimilarJob from "./SimilarJob";
import CareersApplyNow from "./CareersApply";
import ChatBotPage from "./ChatBotPage";
import queryString from "query-string";

import { Trans, useTranslation } from "react-i18next";
import axios from "axios";

function CareersJobdeatails() {
  let loaderDetails = useLoaderData();
  console.log("careers jobdeatails",loaderDetails?.jobDetails?._id);
  const [Loactionreslut, setLocationreslut] = useState("NA");
  const [JobDetails, setJobDetails] = useState(null);
  const [campaignID, setcampaignID] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // console.log(data);

    if (loaderDetails?.jobDetails) {
      setJobDetails(loaderDetails?.jobDetails);
      setcampaignID(loaderDetails?.jobDetails?._id);
    }
  }, [loaderDetails]);
  const { post: getPagevisits } = usePost("/pagevisit");
  const { post: setcookies } = usePost("/setcookie");
  useEffect(() => {
    if (JobDetails?.metaData) {
      i18n.changeLanguage(
        JobDetails?.metaData?.languageCode?.replace("-", "_") || "en_us"
      );
    }
  }, [JobDetails]);

  const previousURL = React.useRef(null);

  const getpagevisitsdata = () => {
    if (
      loaderDetails?.companydetails?.companyID &&
      loaderDetails?.companydetails?.companyName &&
      JobDetails?.title
    ) {
      let path = window.location.href;
      let title;
      let titleExists = true;
      let page = "";
      let subPage = "";
      let Jobtitle;
      if (JobDetails?.displayName) {
        if (JobDetails?.displayName == "Campaign Title") {
          Jobtitle = JobDetails?.title ?? "unkown";
        } else {
          Jobtitle = JobDetails?.job?.details?.jobTitle?.text ?? "unkown";
        }
      } else {
        Jobtitle = JobDetails?.title ?? "unkown";
      }
      if (path.includes("careers/jobdetails")) {
        title = `${Jobtitle}`;
        page = "Jobdetails";
        subPage = "Jobdetails";
      } else {
        titleExists = false;
        title = loaderDetails?.companydetails?.companyName;
      }
      let previousTableBody = JSON.parse(JSON.stringify(document.title));

      // if (
      //   titleExists &&
      //   title &&
      //   previousURL.current != window.location.href &&
      //   JSON.stringify(previousTableBody) !== JSON.stringify(title)
      // ) {
      //   getPagevisits({
      //     url: window.location.href,
      //     title: title,
      //     page: page,
      //     subPage: subPage,
      //     // userID: user?._id ?? "",
      //     companyID: loaderDetails?.companydetails?.companyID,
      //     campaignID: campaignID,
      //   }).then((res) => {
      //     console.log(res);
      //   });
      // }
      previousURL.current = window.location.href;
      document.title = title;
    }
  };
  useEffect(() => {
    if (campaignID && campaignID !== null) {
      getpagevisitsdata();
    }
  }, [campaignID]);
  useEffect(() => {
    let objectWithNonEmptyCity = null;
    for (
      let i = 0;
      i < loaderDetails?.jobDetails?.job?.details?.locations.length;
      i++
    ) {
      const location = loaderDetails?.jobDetails?.job?.details?.locations[i];
      if (location.city && location.city.trim() !== "") {
        objectWithNonEmptyCity = location;
        break;
      }
    }
    if (objectWithNonEmptyCity !== null) {
      setLocationreslut(objectWithNonEmptyCity);
    } else {
      setLocationreslut("NA");
    }
  }, [Loactionreslut, loaderDetails]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [loaderDetails]);

  const [cookies, Setcookies] = useState(
    loaderDetails?.cookies == "true" ? true : false
  );
  const handlecookies = (data) => {
    setcookies({
      cookieName: "accepted",
      cookieValue: data,
    })
      .then((res) => {
        console.log(res);
        Setcookies(true);
      })
      .catch((err) => {
        console.log(err.stack);
      });
  };
  // console.log(JobDetails?.job?.requirementsdetails?.offeredSalary?.text);
  const section1Ref = useRef(null);
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  // const [applynowdata, setapplynowdata] = useState(false);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     if (window.location.search.includes("rid")) {
  //       setapplynowdata(true);
  //     }
  //   }
  // }, [loaderDetails]);
  const [rid, setRid] = useState(null);
  const [iid, setIid] = useState(null);
  const [jid, setJid] = useState(null);
  const [roundNumber, setRoundNumber] = useState(null);
  const [resumeCookie, setResumeCookie] = useState(null);
  const [openLinkProfile, setOpenLinkProfile] = useState(false);

  // console.log("resumeCookieresumeCookie", resumeCookie);

  async function handleApply() {
    if (resumeCookie && !rid && !iid) {
      setOpenLinkProfile(true);
    } else {
      scrollToSection(section1Ref);
    }
  }

  const getResumeCookie = async () => {
    try {
      let cookieDetails = await axios.get(
        window.location.origin + "/jsv4/webapi/getcookie"
      );
      cookieDetails = cookieDetails?.data;
      // console.log("cookieDetailscookieDetails", cookieDetails);

      if (cookieDetails["Guest_User_ResumeID"]) {
        setResumeCookie(cookieDetails["Guest_User_ResumeID"]);
      } else {
        setResumeCookie(null);
      }
    } catch (error) {
      console.log("Error in getCookies : ", error);
    }
  };
  const isMounted = useRef(false);
  console.log("rid", rid);
  useEffect(() => {
    if (typeof window !== "undefined" && !isMounted.current) {
      const searchParams = window.location.search;
      const parameters = queryString.parse(searchParams);
      if (!parameters.rid && !parameters.iid) {
        getResumeCookie();
        isMounted.current = true;
      }
    }
  }, [iid, rid]);

  return (
    <>
      {loaderDetails?.jobDetails?._id ? (
        <>
          <div className="container pt-5 careerscontainer">
            <div className="row align-items-center  pt-5 d-flex p-3">
              <div className="col-md-12 py-3  col-sm-12  col-lg-2">
                {JobDetails?.clientsLogo?.clientLogo && (
                  <img
                    src={JobDetails?.clientsLogo?.clientLogo}
                    // src={loaderDetails?.companydetails?.companyMinifiedLogo}
                    // src="https://recruiterqa.maprecruit.com/images/coarthatechnosolutionsIN/logos/552d016291bad07ccbb455fe_5fb276fa123f55bd44e1569d_mr.jpeg"
                    className="rounded clientLogo clientLogo-sm"
                    width={120}
                  />
                )}
              </div>
              <div className="col-md-12 col-lg-10  col-sm-12 p-0">
                <h2
                  className="text-capitalize textOverflow heading"
                  title={
                    JobDetails?.displayName == "Campaign Title"
                      ? JobDetails?.title ?? "unkown"
                      : JobDetails?.job?.details?.jobTitle?.text ?? "unkown"
                  }
                >
                  {JobDetails?.displayName ? (
                    <>
                      {JobDetails?.displayName == "Campaign Title" ? (
                        <span>{JobDetails?.title ?? "unkown"}</span>
                      ) : (
                        <>
                          {JobDetails?.job.details?.jobTitle?.text ?? "unkown"}
                        </>
                      )}
                    </>
                  ) : (
                    <>{JobDetails?.title ?? "unkown"}</>
                  )}
                </h2>
              </div>
            </div>
            <div
              className="row text-gray-700 mb-md-0 w-100 align-items-center d-flex  mt-2"
              id="jobdetailssecrowcss"
              style={{ fontSize: "large" }}
            >
              <div className="col-sm-12 col-md-12 col-lg-8 pr-0">
                <div className="row w-100 ">
                  <div className="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center">
                    <i
                      className="fa fa-building fa-1x text-primary pe-2"
                      title="Company Name"
                    ></i>
                    <div
                      className="text-capitalize textOverflow"
                      title={
                        JobDetails?.job?.details?.company?.text ??
                        "Not Specified"
                      }
                    >
                      {JobDetails?.job?.details?.company?.text ??
                        "Not Specified"}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center">
                    <i className="fa-solid fa-location-dot  fa-1x text-primary pe-2"></i>
                    <div
                      className="text-capitalize textOverflow"
                      title={
                        Loactionreslut?.text
                          ? Loactionreslut.text
                          : Loactionreslut?.city
                          ? (Loactionreslut.city,
                            Loactionreslut.state,
                            Loactionreslut.country)
                          : "NA"
                      }
                    >
                      {/* {Loactionreslut?.city ? (
                        <>
                          {Loactionreslut?.city}, {Loactionreslut?.state},{" "}
                          {Loactionreslut?.country}
                        </>
                      ) : (
                        "NA"
                      )} */}
                      {Loactionreslut?.text ? (
                        Loactionreslut.text
                      ) : Loactionreslut?.city ? (
                        <>
                          {Loactionreslut.city}, {Loactionreslut.state},{" "}
                          {Loactionreslut.country}
                        </>
                      ) : (
                        "NA"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 padding-top-sm-2rem d-flex">
                {/* <button className="btn btn-primary-soft  text-gray-700 me-2 py-3">
              Refer a Friend
            </button> */}
                <CareersReferFriend JobDetails={JobDetails} />
                <button
                  className="btn btn-primary"
                  onClick={() => handleApply()}
                >
                  <Trans i18nKey="apply" defaults="Apply" />
                </button>
              </div>
            </div>
            <hr></hr>
            <div className="row w-100">
              <div className="col-12 col-md-8">
                <div className="card mb-3 careerscards">
                  <div className="card-body">
                    <h3 className="text-capitalize heading">
                      {" "}
                      <Trans
                        i18nKey="jobDescription"
                        defaults="Job Description"
                      />
                    </h3>
                    <div className="Jobdetailstext">
                      {/* {JobDetails?.job?.details?.jobDescription?.text && */}
                      <ShowMore
                        text={
                          JobDetails?.job?.details?.jobDescription?.text ??
                          "Not Specified"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card mb-3 careerscards">
                  <div className="card-body">
                    <h3 className="text-capitalize heading">
                      {" "}
                      <Trans i18nKey="jobDetails" defaults="Job Details" />
                    </h3>
                    <div
                      className="text-capitalize textOverflow pb-3"
                      title={
                        JobDetails?.displayName == "Campaign Title"
                          ? JobDetails?.title ?? "unkown"
                          : JobDetails?.job?.details?.jobTitle?.text ?? "unkown"
                      }
                    >
                      {JobDetails?.displayName ? (
                        <div>
                          {JobDetails?.displayName == "Campaign Title" ? (
                            <span className="fs-6 text-muted">
                              {JobDetails?.title ?? "unkown"}
                            </span>
                          ) : (
                            <span className="fs-6 text-muted">
                              {JobDetails?.job.details?.jobTitle?.text ??
                                "unkown"}
                            </span>
                          )}
                        </div>
                      ) : (
                        <span className="fs-6 text-muted">
                          {loaderDetails?.jobDetails?.title ?? "unkown"}
                        </span>
                      )}
                    </div>
                    <div className=" text-capitalize textOverflow pb-3">
                      <i
                        className="fa fa-location-dot fa-1x text-primary pe-2"
                        title="Location"
                      ></i>
                      <span
                        title={
                          Loactionreslut?.text
                            ? Loactionreslut.text
                            : Loactionreslut?.city
                            ? (Loactionreslut.city,
                              Loactionreslut.state,
                              Loactionreslut.country)
                            : "NA"
                        }
                      >
                        {/* {Loactionreslut?.city ? (
                      <>
                        {Loactionreslut?.city}, {Loactionreslut?.state},{" "}
                        {Loactionreslut?.country}
                      </>
                    ) : (
                      "Not Specified"
                    )} */}
                        {Loactionreslut?.text ? (
                          Loactionreslut.text
                        ) : Loactionreslut?.city ? (
                          <>
                            {Loactionreslut.city}, {Loactionreslut.state},{" "}
                            {Loactionreslut.country}
                          </>
                        ) : (
                          "NA"
                        )}
                      </span>
                    </div>
                    <div className=" text-capitalize textOverflow pb-3">
                      <i
                        className="fa fa-briefcase fa-1x text-primary pe-2"
                        title="Experience"
                      ></i>
                      <span
                        title={
                          (JobDetails?.job?.requirements?.yearsOfExperience
                            ?.minYears == 0 || JobDetails?.job?.requirements?.yearsOfExperience
                            ?.minYears) && JobDetails?.job?.requirements?.yearsOfExperience
                            ?.maxYears ?
                            JobDetails?.job?.requirements?.yearsOfExperience
                            ?.minYears + " - " + JobDetails?.job?.requirements?.yearsOfExperience
                            ?.maxYears + " Years"
                            : "Not Specified"
                        }
                      >
                        {(JobDetails?.job?.requirements
                          ?.yearsOfExperience?.minYears == 0 || JobDetails?.job?.requirements
                          ?.yearsOfExperience?.minYears) && JobDetails?.job?.requirements
                          ?.yearsOfExperience?.maxYears ? 
                          <span>
                            {JobDetails?.job?.requirements
                          ?.yearsOfExperience?.minYears + " - " + JobDetails?.job?.requirements
                          ?.yearsOfExperience?.maxYears + " Years" }
                          </span> : <span>
                            <Trans
                              i18nKey="notSpecified"
                              defaults="Not Specified"
                            />
                          </span>

                        }
                        {/* {JobDetails?.job?.requirements?.yearsOfExperience
                          ?.text ? (
                          <>
                            {parseInt(
                              JobDetails?.job?.requirements?.yearsOfExperience
                                ?.maxYears
                            ) == 50 ? (
                              <>
                                {
                                  JobDetails?.job?.requirements
                                    ?.yearsOfExperience?.minYears
                                }{" "}
                                Years
                              </>
                            ) : (
                              <>
                                {
                                  JobDetails?.job?.requirements
                                    ?.yearsOfExperience?.text
                                }
                              </>
                            )}
                          </>
                        ) : (
                          <span>
                            <Trans
                              i18nKey="notSpecified"
                              defaults="Not Specified"
                            />
                          </span>
                        )} */}
                      </span>
                    </div>
                    <div className=" text-capitalize textOverflow pb-3">
                      <i
                        className="fa fa-coins fa-1x text-primary pe-2"
                        title="Salary"
                      ></i>
                      {(JobDetails?.job?.details?.offeredSalary?.minvalue != 0 
                       || JobDetails?.job?.details?.offeredSalary?.minvalue == 0)
                       && JobDetails?.job?.details?.offeredSalary?.maxvalue != 0 && 
                       <span>
                        {
                          JobDetails?.job?.details?.offeredSalary?.currency == "USD" ? "$" + JobDetails?.job?.details?.offeredSalary?.minvalue + " - " + "$" + JobDetails?.job?.details?.offeredSalary?.maxvalue
                          : JobDetails?.job?.details?.offeredSalary?.currency +" "+ JobDetails?.job?.details?.offeredSalary?.minvalue + " - " + JobDetails?.job?.details?.offeredSalary?.currency +" "+ JobDetails?.job?.details?.offeredSalary?.maxvalue
                        }
                        {" "+JobDetails?.job?.details?.offeredSalary?.period}
                       </span>
                      }
                    </div>
                    <div className=" text-capitalize textOverflow pb-3">
                      <i
                        className="fa fa-address-card  fa-1x text-primary pe-2"
                        title="Job Type"
                      ></i>
                      <span
                        title={
                          JobDetails?.job?.details?.jobTitle?.jobType &&
                          JobDetails?.job?.details?.jobTitle?.jobType !== ""
                            ? JobDetails?.job?.details?.jobTitle?.jobType
                            : "Not Specified"
                        }
                      >
                        {/* {JobDetails?.job?.details?.jobTitle?.jobType */}
                        {JobDetails?.job?.details?.jobTitle?.jobType &&
                        JobDetails?.job?.details?.jobTitle?.jobType !== "" ? (
                          <>{JobDetails?.job?.details?.jobTitle?.jobType}</>
                        ) : (
                          <span>
                            {" "}
                            <Trans
                              i18nKey="notSpecified"
                              defaults="Not Specified"
                            />
                          </span>
                        )}
                      </span>
                    </div>
                    <div className=" text-capitalize textOverflow pb-3">
                      <i
                        className="fa fa-graduation-cap   fa-1x text-primary pe-2"
                        title="Education"
                      ></i>
                      <span
                        title={
                          JobDetails?.job?.requirements?.education.length > 0
                            ? JobDetails?.job?.requirements?.education.map(
                                (education, index) =>
                                  education.degree?.text &&
                                  education.degree.text
                              )
                            : "Not Specified"
                        }
                      >
                        {/* {JobDetails?.job?.requirements?.education[0]?.degree
                          ?.text ? (
                          <>
                            {
                              JobDetails?.job?.requirements?.education[0]
                                ?.degree?.text
                            }
                          </>
                        ) : (
                          <span>Not Specified</span>
                        )} */}
                        {JobDetails?.job?.requirements?.education.length > 0 ? (
                          <>
                            {JobDetails?.job?.requirements?.education.map(
                              (education, index) => (
                                <React.Fragment key={index}>
                                  {education.degree?.text && (
                                    <>
                                      {education.degree.text}
                                      {index <
                                        JobDetails?.job?.requirements?.education
                                          .length -
                                          1 && <span>, </span>}
                                    </>
                                  )}
                                </React.Fragment>
                              )
                            )}
                          </>
                        ) : (
                          <span>
                            <Trans
                              i18nKey="notSpecified"
                              defaults="Not Specified"
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {JobDetails?.job?.requirements?.skills.length > 0 && (
              <div className="row w-100 mb-3">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="text-capitalize heading">
                        {" "}
                        <Trans i18nKey="skills" defaults="Skills" />
                      </h3>
                      <div>
                        <TagGroup>
                          {JobDetails?.job?.requirements?.skills?.map((x) =>
                            x?.skills?.map((y) => (
                              <Tag
                                key={y?.text}
                                className="skillsttag px-3 py-2 rounded-pill"
                              >
                                {y?.text}
                              </Tag>
                            ))
                          )}
                        </TagGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {JobDetails?.job?.details?.company?.description &&
              JobDetails?.job?.details?.company?.description !== "" && (
                <div className="row w-100 mb-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="text-capitalize heading">
                          <Trans
                            i18nKey="companyDescription"
                            defaults="Company Description"
                          />
                        </h3>
                        <b className="text-capitalize">
                          <div className="text-primary my-3">
                            <Trans i18nKey="about" defaults="About" />{" "}
                            {JobDetails?.job?.details?.company?.text}
                          </div>
                        </b>

                        <div>
                          {JobDetails?.job?.details?.company?.description &&
                          JobDetails?.job?.details?.company?.description !== ""
                            ? JobDetails?.job?.details?.company?.description
                            : "Not Specified"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className="row w-100 mb-3">
              <div className="col-12">
                <SimilarJob JobDetails={JobDetails} />
              </div>
            </div>
            <div className="row w-100 mt-5 mb-3">
              <div className="col-12" ref={section1Ref}>
                {/* <div
              className="card border border-primary border-4 border-end-0 border-bottom-0 border-start-0 profileFormDiv"
              
            > */}
                <CareersApplyNow
                  JobDetails={JobDetails}
                  //  rid={rid}
                  setRid={setRid}
                  //  iid={iid}
                  setIid={setIid}
                  //  jid={jid}
                  setJid={setJid}
                  //  roundNumber={roundNumber}
                  setRoundNumber={setRoundNumber}
                  resumeCookie={resumeCookie}
                  setResumeCookie={setResumeCookie}
                  openLinkProfile={openLinkProfile}
                  setOpenLinkProfile={setOpenLinkProfile}
                  section1Ref={section1Ref}
                  scrollToSection={scrollToSection}
                />

                {/* <div className="card-body">
                <div className="p-4">
                  {!applynowdata ? (
                    <CareersApplyNow JobDetails={JobDetails} />
                  ) : (
                    <></>
                  )}
                </div>
              </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          {!cookies && (
            <div className="cookie-container">
              <Trans
                i18nKey="Weusecookiestoensurethatweofferyouthebestpossibleexperienceonoursite.Formoredetails,readour"
                defaults="We use cookies to ensure that we offer you the best possible experience on our site. For more details, read our"
              />{" "}
              <a
                className="text-white"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.maprecruit.ai/data-privacy"
              >
                <span>
                  <Trans i18nKey="privacyPolicy" defaults="privacy policy" />
                </span>
              </a>
              .
              <button
                className="btn btn-primary m-2 p-3"
                onClick={() => {
                  handlecookies("true");
                }}
              >
                <Trans i18nKey="accept" defaults="Accept" />
              </button>
              <button
                className="btn btn-primary m-2 p-3"
                onClick={() => {
                  handlecookies("false");
                }}
              >
                <Trans i18nKey="reject" defaults="Reject" />
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <img src="/assets/images/campaignClosed-min.png" width={800} />
        </div>
      )}
      <ChatBotPage
        loaderDetails={loaderDetails}
        rid={rid}
        setRid={setRid}
        iid={iid}
        setIid={setIid}
        jid={jid}
        setJid={setJid}
        roundNumber={roundNumber}
        setRoundNumber={setRoundNumber}
      />
    </>
  );
}

export default CareersJobdeatails;
