import React, { useState, useContext } from "react";
import { Card, Spinner, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./Login.css";
import LoginIcon from "./LoginIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePost from "../../customhooks/UsePost";
import UserContext from "../../app/context/UserContext";
import SocialLogin from "./SocialLogin";
import { Notification, useToaster } from "rsuite";

export default function Login({
  setTarget,
  from,
  setOpenCreateProfile,
  setOpenModal,
  setOpenLinkProfile,
  setOpenRefer,
  setPage,
  setType,
  setGuestEmail
}) {
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const toaster = useToaster();

  const {
    post: loginPost,
    data,
    isLoading: loginPostLoading,
    error,
  } = usePost("/user/login");
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const handleClose = () => setShowProfileModal(false);
  const handleShow = () => setShowProfileModal(true);

  const getOrigin = (url) => {
    let t = new URL(url);
    return t?.origin;
  };

  function handleClick(){
    setOpenModal(false);
    setOpenCreateProfile(true);
    setPage("guest");
  }

  const onSubmit = async (data) => {
    // console.log("data", data);

    let flag = true;
    if (
      data?.email === null ||
      data.email === undefined ||
      data?.password === null ||
      data.password === undefined
    ) {
      flag = false;
    }
    if (flag) {
      let body = {
        email: data?.email,
        password: data.password,
      };
      // console.log("body", body);
      loginPost(body)
        .then((res) => {
          // console.log("userDetails", res?.user);
          // toaster.push(
          //   <Notification
          //     type={"success"}
          //     header={<Trans i18nKey="success" defaults="Success" />}
          //     closable
          //   >
          //     <Trans
          //       i18nKey="WelcomeBack!YourenowLoggedin"
          //       defaults="Welcome back! You're now logged in."
          //     />
          //   </Notification>,
          //   {
          //     placement: "topEnd",
          //   }
          // );
          if(res?.type == "Create Password"){
          toaster.push(
            <Notification type={"success"} header={t("success", { defaultValue: "Success" })} closable>
              {t("youAlreadyHaveAnGuestAccount", {
                defaultValue: res?.message,
              })}
            </Notification>,
            { placement: "topEnd" }
          );
          setTarget("forgotPassword");
          setType("Create Password");
          setGuestEmail(data?.email)
          }
          if (res?.user) {
            setUser(res?.user);

          setOpenModal(false);
          toaster.push(
            <Notification type={"success"} header={t("success", { defaultValue: "Success" })} closable>
              {t("welcomeBackYourenowLoggedin", {
                defaultValue: "Welcome back You are Sucessfully Loged in",
              })}
            </Notification>,
            { placement: "topEnd" }
          );
          
          if (from == "applyNow") {
            if (res?.user?.resumeID != "") {
              setOpenLinkProfile(true);
            } else {
              setOpenCreateProfile(true);
            }
          } else if (from == "referFriend") {
            setOpenRefer(true);
          }
        }
        })
        .catch((error) => {
          // console.log("error is:", error);

          if (error?.response?.data?.message?.includes("User not found")) {
            // console.log("user not found");
            setError("email", {
              type: "manual",
            });
          }

          if (error?.response?.data?.message?.includes("Invalid credentials")) {
            // console.log("password is invalid");
            setError("password", {
              type: "manual",
            });
          }
        });
    }
  };

  return (
    <>
      <Card className="mt-5">
        <Card.Body>
          {/* <LoginHeader from={from} /> */}
          <LoginIcon />
          <h5 className="d-flex justify-content-center mb-2">
            <Trans
              i18nKey="readyToFindYourDreamJob"
              defaults="Ready to find your dream job"
            />
            {"?"}
          </h5>
          <h5 className="d-flex justify-content-center mb-3">
            <Trans
              i18nKey="letsCreateAnAccountToGetYourProfileStarted"
              defaults="Let's create an account to get your profile started"
            />
            {"."}
          </h5>
          <p className="d-flex justify-content-center fs-6 m-0">
            <Trans
              i18nKey="withYourTrcCandidateProfileYouCanApplyTo"
              defaults="With your TRC Candidate Profile, you can apply to"
            />
          </p>
          <p className="d-flex justify-content-center fs-6 m-0">
            <Trans
              i18nKey="NewJobPostingsReceiveAlertsOnHotJobsTrack"
              defaults="new job postings, receive alerts on hot jobs, track"
            />
          </p>
          <p className="d-flex justify-content-center mb-3 mt-0 fs-6">
            <Trans
              i18nKey="ApplicationsSubmittedAndSoMuchMore"
              defaults="applications submitted and so much more"
            />
            {"!"}
          </p>

          <div
            className={`d-grid mx-3 ${from == "applyNow" ? null : "d-none"}`}
            // className="d-grid mx-3"
          >
            <Button
              className="guestButtonCss"
              onClick={handleClick}
            >
              <Trans i18nKey="continueAsGuest" defaults="Continue as Guest" />
            </Button>
          </div>
          <div className="mt-4 mb-3 mx-3">
            <label htmlFor="email" className="form-label m-0">
              <Trans i18nKey="email" defaults="Email" />
            </label>
            <input
              type="email"
              className={`form-control`}
              id="email"
              // placeholder={t("enterYourEmail", { defaultValue: "Enter your email"})}
              placeholder={
                t("enter", { defaultValue: "Enter" }) +
                " " +
                t("email", { defaultValue: "Email" })
              }
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                },
              })}
            />
            {errors?.email && errors?.email.type == "required" && (
              <span className="text-danger">
                {t("emailIsRequired", { defaultValue: "Email is required" })}
              </span>
            )}
            {errors?.email && errors?.email.type == "pattern" && (
              <span className="text-danger">
                {t("invalidEmailAddress", {
                  defaultValue: "Invalid email address",
                })}
              </span>
            )}
          </div>
          {/* // strong password with one uppercase,lowercase,one special character letters,one number and min 8 characters*/}
          <div className="mb-3 mx-3">
            <label htmlFor="password" className="form-label m-0">
              <Trans i18nKey="password" defaults="Password" />
            </label>
            <div className="d-flex">
              <input
                type={showPassword ? "text" : "password"}
                className={`form-control`}
                id="password"
                placeholder={t("enterYourPassword", {
                  defaultValue: "Enter your password",
                })}
                // placeholder={t("enter", { defaultValue: "Enter" })+ " "+t("password",{ defaultValue: "Password" })}
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 8,
                  },
                  pattern: {
                    // value:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/,
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s])[a-zA-Z\d\S]{8,}$/,
                  },
                })}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                style={{ marginLeft: -30, paddingTop: 10 }}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {errors?.password && errors?.password.type == "required" && (
              <span className="text-danger">
                {t("passwordIsRequired", {
                  defaultValue: "Password is required",
                })}
              </span>
            )}
            {errors?.password && errors?.password.type == "minLength" && (
              <span className="text-danger">
                {t("passwordMustHaveAtLeast8Characters", {
                  defaultValue: "Password must have at least 8 characters",
                })}
              </span>
            )}
            {errors?.password && errors?.password.type == "pattern" && (
              <span className="text-danger">
                {t(
                  "passwordMustContainAtLeastoneLowercaseLetter,OneUppercaseLetter,OneNumber,AndOneSpecialCharacter",
                  {
                    defaultValue:
                      "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character",
                  }
                )}
              </span>
            )}
          </div>
          <div
            className=" d-flex justify-content-end me-3 mt-2 mb-2 text-primary clickCss"
            onClick={() => {
              setTarget("forgotPassword");
              setType("");
            }}
          >
            <Trans i18nKey="forgotPassword" defaults="Forgot Password" />
            {"?"}
          </div>

          {errors?.password && errors?.password.type == "manual" && (
            <span className="text-danger ms-3 fs-6">
              {t("invalidCredentials", { defaultValue: "Invalid credentials" })}
            </span>
          )}
          {errors?.email && errors?.email.type == "manual" && (
            <span className="text-danger ms-3 fs-6">
              {t("userNotFound", { defaultValue: "User not found" })}
            </span>
          )}
          {errors?.email && errors?.email.type == "favourite" && (
            <span className="text-danger ms-3 fs-6">
              {t("youNeedToLoginToSaveAsFavourite", {
                defaultValue: "You need to Login to save as favourite",
              })}
            </span>
          )}

          <div className="d-grid mx-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit(onSubmit)}
            >
              {loginPostLoading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              <Trans i18nKey="login" defaults="Login" />
            </button>
          </div>
          <div className=" d-flex justify-content-center mt-2">
            <Trans
              i18nKey="don'tHaveAnAccount"
              defaults="Don't have an account"
            />
            {"?"} <span className="ms-1"></span>
            <div
              className="text-primary clickCss"
              onClick={() => {
                setTarget("signUp");
              }}
            >
              <Trans i18nKey="signup" defaults="Signup" />{" "}
            </div>
          </div>

          {/* <div
            className={`d-flex justify-content-center mt-2 ${from == "applyNow" ? null : "d-none"}`}
            // onClick={() => {
            //   if (from == "favourite") {
            //     setError("email", {
            //       type: "favourite",
            //     });
            //   } else {
            //     setOpenModal(false)
            //     setOpenCreateProfile(true)
            //     setPage("guest")
            //   }
            // }}
            onClick={() => {
              setOpenModal(false)
              setOpenCreateProfile(true)
              setPage("guest")
            }}
          >
            <span>
              <Trans i18nKey="continueAs" defaults="Continue as" />
            </span>
            <span className="text-primary px-1 clickCss">
              <Trans i18nKey="guest" defaults="Guest" />
            </span>
          </div> */}

          <div className="row ms-2 mt-3 me-2">
            <div className="col-5">
              <hr></hr>
            </div>
            <div className="d-flex col-2 justify-content-center mt-2">
              <Trans i18nKey="or" defaults="Or" />
            </div>
            <div className="col-5">
              <hr></hr>
            </div>
          </div>

          {/* <div className="row">
                        <div className="col-12 d-grid ml-2">
                            <button
                                className="btn border rounded bg-light"
                                type="button"
                            >
                                <div className="row">
                                    <div className="col-1">
                                     
                                    </div>
                                    <div className="col-10">
                                        <Trans i18nKey="loginWithGoogle" defaults="Login with Google" />
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div> */}
          {/* <div className="row mt-2">
                            <div className="col-sm-12 justify-content-center d-flex">
                                <a href="/login/google">
                                    <div
                                        className="google-btn"
                                        style={{
                                            opacity: "1"

                                        }}
                                    >
                                        <div className="google-icon-wrapper">
                                            <img
                                                className="google-icon"
                                                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                                            />
                                        </div>
                                        <p className="btn-text">
                                            <b><Trans i18nKey="signInWithGoogle" defaults="Sign in with Google" /></b>
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div> */}
          {/* <div className="row mt-2">
                            <div className="col-sm-12 justify-content-center d-flex">
                                <div
                                    className="facebook-btn"
                                    style={{
                                        opacity: "1"

                                    }}
                                >
                                    <div className="facebook-icon-wrapper">
                                        <img
                                            className="facebook-icon"
                                            src=""
                                        />

                                    </div>
                                    <p className="btn-text me-5">
                                        <b><Trans i18nKey="facebook" defaults="Facebook" /></b>
                                    </p>
                                </div>
                            </div>
                        </div> */}
          {/* <div className="row mt-2">
                            <div className="col-sm-12 justify-content-center d-flex">
                                <a
                                    href="/login/outlook"
                                >
                                    <div
                                        className="btn signInWithMicroSoft_E"
                                        style={{
                                            opacity: "1"

                                        }}
                                    >
                                        <span>
                                            <img
                                                src="/assets/images/outlook.svg"
                                                style={{ height: "32px", padding: "0rem 0.5rem" }}
                                            />
                                        </span>
                                        <span><Trans i18nKey="microsoft" defaults="Microsoft" /></span>
                                    </div>
                                </a>
                            </div>
                        </div> */}
          <div className="row mt-1">
            <div className="col-sm-12 justify-content-center d-flex ">
              {/*    or you can login with */}
              <Trans i18nKey="youCanLoginWith" defaults="you can login with" />
            </div>
          </div>
          <div className="row mt-2">
            <SocialLogin />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

