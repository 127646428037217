import React, { useState, useContext, useEffect } from "react";
import usePost from "../../../customhooks/UsePost";
import UserContext from "../../../app/context/UserContext";
import {
  Dropdown,
  Loader,
  Modal,
  Placeholder,
  Tag,
  Tooltip,
  Whisper,
} from "rsuite";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faBuilding,
  faEllipsisV,
  faLink,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Button, NavItem } from "react-bootstrap";

const ProfileRecommendedJobs = () => {
  const { user, setUserDetails } = useContext(UserContext);
  const [id, setId] = useState(user?.resumeID ? user.resumeID : "");
  const { t } = useTranslation();
  useEffect(() => {
    if (user?.resumeID) {
      setId(user.resumeID);
    }
  }, [user]);

  const {
    post: resumeRecommendedJobs,
    data,
    isLoading: resumeRecommendedJobsLoading,
    error,
  } = usePost("/getresumerecommendedjobs");

  const [resumeDetails, setResumeDetails] = useState({});
  const [resume, setResume] = useState([]);
  if (data?.ActiveClientByUser) {
    obj["clientID"] = data.ActiveClientByUser;
  }
  useEffect(() => {
    if (user && user != {}) {
      setResumeDetails({
        resumeID: id,
        companyID: user?.companyID,
        userID: user?._id,
      });
    }
  }, [user, id]);

  useEffect(() => {
    if (
      resumeDetails.resumeID &&
      resumeDetails.resumeID != "" &&
      resumeDetails.companyID &&
      resumeDetails.companyID != "" &&
      resumeDetails.userID &&
      resumeDetails.userID != ""
    ) {
      resumeRecommendedJobs(resumeDetails)
        .then((res) => {
          if (res) {
            setResume(res?.data);
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  }, [resumeDetails]);

  const getMriColor = (score) => {
    if (score >= 80) {
      return "#56c774";
    } else if (score >= 60 && score < 80) {
      return "#3da9dc";
    } else if (score >= 40 && score < 60) {
      return "#f9bc0e";
    } else {
      return "#ff8e8e";
    }
  };
  const statusColor = (status) => {
    if (status == "Active") {
      return "green";
    } else if (status == "Closed") {
      return "red";
    } else {
      return "";
    }
  };

  return (
    <>
      {resumeRecommendedJobsLoading ? (
        <div className="card">
          <div className="card-body">
            <Placeholder.Grid rows={2} columns={2} active />
          </div>
        </div>
      ) : (
        <div className="row my-1">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    {/* <div className="d-flex justify-content-between align-items-center">
                      <h6 className="my-2 text-primary">
                        <Trans
                          i18nKey="profileRecommendedJobs"
                          defaults="Recommended Jobs"
                        />
                      </h6>
                    </div> */}
                    <>
                      {resume?.length > 0 ? (
                        <>
                          {resume?.map((jobs) => {
                            return (
                              <div
                                className="row border-bottom py-2 mx-1 align-items-center w-100"
                                key={jobs?._id}
                              >
                                <div className="col-1 my-3 d-flex justify-content-center align-items-center">
                                  <Whisper
                                    placement="auto"
                                    trigger="hover"
                                    speaker={
                                      <Tooltip>
                                        {t("matchingAndRankingIndex", {  defaultValue:"Matching and Ranking Index",  })}
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      className="text-white rounded-circle justify-content-center align-items-center d-flex"
                                      style={{
                                        width: "42px",
                                        height: "42px",
                                        backgroundColor: getMriColor(
                                          jobs?.mriScore * 10
                                        ),
                                      }}
                                    >
                                      {jobs?.mriScore?.toFixed(1)}
                                    </span>
                                  </Whisper>
                                </div>
                                <div className="col-9">
                                  <div className="row my-1">
                                    <div className="col-7">
                                      <Whisper
                                        placement="left"
                                        trigger="hover"
                                        speaker={
                                          <Tooltip>
                                            {jobs?._source?.title
                                              ? jobs?._source?.title
                                              : t("na", { defaultValue: "NA" })}
                                          </Tooltip>
                                        }
                                      >
                                        {/* <Nav.Item>
                                        <Nav.Link
                                          as={NavLink}
                                          to={"/showcampaign/intelligence/" + jobs?._id}
                                          className="text-primary"
                                        > */}
                                       <a class="text-decoration-none" href={"/jobdetails/"+jobs._id} target="_blank">
                                         
                                        <h6 className="text-primary">
                                          {jobs?._source?.title
                                            ? jobs?._source?.title
                                            : t("na", { defaultValue: "NA" })}
                                        </h6>
                                        </a>
                                        {/* </Nav.Link>
                                      </Nav.Item> */}
                                      </Whisper>
                                    </div>
                                    <div className="col-5">
                                      <div className="d-flex align-items-center">
                                        <b>
                                          {t("jobID", { defaultValue: "Job ID: ",  })}
                                        </b>
                                        <Whisper
                                          placement="auto"
                                          trigger="hover"
                                          speaker={
                                            <Tooltip>
                                              {jobs?._source?.passcode
                                                ? jobs?._source?.passcode
                                                : t("na", { defaultValue: "NA", })}
                                            </Tooltip>
                                          }
                                        >
                                          <span className="ms-2 text-primary">
                                            {jobs?._source?.passcode
                                              ? jobs?._source?.passcode
                                              : t("na", { defaultValue: "NA" })}
                                          </span>
                                        </Whisper>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-4">
                                      <Whisper
                                        placement="auto"
                                        trigger="hover"
                                        speaker={
                                          <Tooltip>
                                            {t("jobTitle", {
                                              defaultValue: "Job Title",
                                            })}
                                          </Tooltip>
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faBriefcase}
                                        ></FontAwesomeIcon>
                                      </Whisper>
                                      <Whisper
                                        placement="auto"
                                        trigger="hover"
                                        speaker={
                                          <Tooltip>
                                            {jobs?._source?.job?.details
                                              ?.jobTitle?.text
                                              ? jobs?._source?.job?.details
                                                  ?.jobTitle?.text
                                              : t("na", { defaultValue: "NA" })}
                                          </Tooltip>
                                        }
                                      >
                                        <span className="ms-2">
                                          {jobs?._source?.job?.details?.jobTitle
                                            ?.text
                                            ? jobs?._source?.job?.details
                                                ?.jobTitle?.text
                                            : t("na", { defaultValue: "NA" })}
                                        </span>
                                      </Whisper>
                                    </div>
                                    <div className="col-4 d-flex align-items-center">
                                      <Whisper
                                        placement="auto"
                                        trigger="hover"
                                        speaker={
                                          <Tooltip>
                                            {t("company", {  defaultValue: "Company" })}
                                          </Tooltip>
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faBuilding}
                                        ></FontAwesomeIcon>
                                      </Whisper>
                                      <span className="ms-2 text-truncate w-9">
                                        <Whisper
                                          placement="auto"
                                          trigger="hover"
                                          speaker={
                                            <Tooltip>
                                              {jobs?._source?.job?.details
                                                ?.company?.text
                                                ? jobs?._source?.job?.details
                                                    ?.company?.text
                                                : t("na", {
                                                    defaultValue: "NA",
                                                  })}
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {jobs?._source?.job?.details
                                              ?.company?.text
                                              ? jobs?._source?.job?.details
                                                  ?.company?.text
                                              : t("na", { defaultValue: "NA" })}
                                          </span>
                                        </Whisper>
                                      </span>
                                    </div>
                                    <div className="col-4">
                                      <Whisper
                                        placement="auto"
                                        trigger="hover"
                                        speaker={
                                          <Tooltip>
                                            {t("location", { defaultValue: "Location"})}
                                          </Tooltip>
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faLocationDot}
                                        ></FontAwesomeIcon>
                                      </Whisper>
                                      <span className="ms-2 text-truncate w-9">
                                        <Whisper
                                          placement="auto"
                                          trigger="hover"
                                          speaker={
                                            <Tooltip>
                                              {jobs?._source?.job?.details
                                                ?.locations?.length > 0
                                                ? jobs?._source?.job?.details
                                                    ?.locations[0]?.text
                                                : t("na", {
                                                    defaultValue: "NA",
                                                  })}
                                            </Tooltip>
                                          }
                                        >
                                          <span className="ms-2">
                                            {jobs?._source?.job?.details
                                              ?.locations?.length > 0
                                              ? jobs?._source?.job?.details
                                                  ?.locations[0]?.text
                                              : t("na", { defaultValue: "NA" })}
                                          </span>
                                        </Whisper>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-1 d-flex justify-content-center align-items-center">
                                  <Tag
                                    color={statusColor(jobs?._source?.status)}
                                  >
                                    {jobs?._source?.status}
                                  </Tag>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="text-center d-flex justify-content-center align-items-center">
                          <Placeholder.Paragraph rows={2} />
                          <Trans
                            i18nKey="noSuggestedRequisitions"
                            defaults="No Suggested Requisitions!"
                          />
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileRecommendedJobs;
