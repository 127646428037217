import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Trans } from "react-i18next";
import { t } from "i18next";

const NoticePeriodFormField = ({
  noticePeriodData,
  control,
  register,
  getValues,
  setValue,
  errors,
  fieldList,
  type,
}) => {
  useEffect(() => {
    if (noticePeriodData) {
      setValue("noticePeriod", noticePeriodData);
    }
  }, [noticePeriodData]);
  return (
    <>
      <div className="form-group">
        <label className="text-dark">
        {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
        </label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
        <div className="d-flex">
          <Controller
            control={control}
            name={"noticePeriod"}
            refs={register("noticePeriod", {
              required: fieldList?.required ? true : false,
            })}
            rules={{
              validate: {
                isNonNegative: (value) => {
                  // console.log(value, "vasdjkgnksngdknsglue");
                  if (value < 0) {
                    return t("valueCannotBeNegative", {
                      defaultValue: "Value cannot be negative",
                    });
                  }
                },
              },
            }}
            defaultValue={""}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="number"
                size="sm"
                className="w-100"
                placeholder={
                  t("enter", { defaultValue: "Enter" }) +
                  " " +
                  t("noticePeriod", { defaultValue: "Notice Period" })
                }
                disabled={
                  type == "customLayout" || fieldList?.editable || !fieldList
                    ? false
                    : true
                }
                value={value}
                onChange={onChange}
              />
            )}
          />
          <span className="d-flex align-items-center px-1">
            <h6 className="p-0 m-0 text-dark">
              <Trans i18nKey="days" defaults="Days" />
            </h6>
          </span>
        </div>

        {errors?.noticePeriod?.type == "required" ? (
          <span className="text-danger">
            {errors?.noticePeriod?.message != "" ? (
              errors?.noticePeriod?.message
            ) : (
              <Trans
                i18nKey="noticePeriodIsRequired"
                defaults="Notice Period is required"
              />
            )}{" "}
          </span>
        ) : (
          <>
            {errors?.noticePeriod && (
              <span className="text-danger">
                {errors?.noticePeriod?.message != ""
                  ? errors?.noticePeriod.message
                  : t("valueCannotBeNegative", {
                      defaultValue: "Value cannot be negative",
                    })}
              </span>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NoticePeriodFormField;
