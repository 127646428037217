import { useForm, Controller, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  isValidPhoneNumber,
  parsePhoneNumber,
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import countryNames from "react-phone-number-input/locale/en";
import PhoneInput from "react-phone-number-input";
// import { t } from "i18next";
import { Trans,useTranslation } from "react-i18next";

const ReferencePhonesForm = ({
  control,
  register,
  phnIndex,
  phones,
  setValue,
  getValues,
  errors,
  fieldObj, setError, clearErrors
}) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `reference[${phnIndex}].phone`,
  });
  const { t } = useTranslation();

  useEffect(() => { }, [phones]);
  const handlePhonenumber = (value, index, mainIndex) => {
    let validatePhoneNumber = false
    if (value) {
      validatePhoneNumber = isValidPhoneNumber(value)

    }
    if (validatePhoneNumber) {
      // clearErrors(`reference[${phnIndex}].phone[${index}].text`)
      clearErrors(`reference[${phnIndex}].email[${index}].text`)

      // if (value && value != "" && value.trim() != "") {
      //   let parsePhoneNumberData = parsePhoneNumber(value);
      //   setValue(
      //     `reference[${mainIndex}].phone[${index}].text`,
      //     parsePhoneNumberData?.number
      //   );
      //   setValue(
      //     `reference[${mainIndex}].phone[${index}].countryCode`,
      //     parsePhoneNumberData?.country
      //   );
      //   setValue(
      //     `reference[${mainIndex}].phone[${index}].country`,
      //     countryNames[parsePhoneNumberData?.country]
      //   );
      //   setValue(
      //     `reference[${mainIndex}].phone[${index}].internationalPhoneCode`,
      //     "+" + parsePhoneNumberData?.countryCallingCode
      //   );
      //   setValue(
      //     `reference[${mainIndex}].phone[${index}].phoneNumberOnly`,
      //     parsePhoneNumberData?.nationalNumber
      //   );
      // }
    }
    if (getValues()?.reference[phnIndex]?.phone?.length > 0) {
      for (let j = 0; j < getValues()?.reference[phnIndex]?.phone?.length; j++) {
        for (let k = j + 1; k < getValues()?.reference[phnIndex]?.phone?.length; k++) {
          if (getValues()?.reference[phnIndex]?.phone[j].text != getValues()?.reference[phnIndex]?.phone[k]?.text) {
            clearErrors(`reference[${phnIndex}].phone[${k}].text`)
          }
        }
      }
    }

  };
  const handlePhoneNumberChange = () => {
    if (getValues()?.reference[phnIndex]?.phone?.length > 0) {
      if(getValues()?.reference[phnIndex]?.phone?.length == 1) {
        clearErrors(`reference[${phnIndex}].phone[${0}].text`)
      } else {
        for (let j = 0; j < getValues()?.reference[phnIndex]?.phone?.length; j++) {
          for (let k = j + 1; k < getValues()?.reference[phnIndex]?.phone?.length; k++) {
            if (getValues()?.reference[phnIndex]?.phone[j].text != getValues()?.reference[phnIndex]?.phone[k]?.text) {
              // console.log("sadkagsdasa", getValues()?.reference[phnIndex]?.phone[k]?.text)
              clearErrors(`reference[${phnIndex}].phone[${k}].text`)
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    // console.log("sfkvnksnfgknsfgn");
    if (getValues()) {
      if (getValues()?.reference[phnIndex]?.phone?.length == 0) {
        append({
          text: "",
        });
      }
    }
  }, [])
  const handlePhnAdd = () => {
    let flag = true
    if (getValues()?.reference[phnIndex]?.phone?.length > 0) {
      for (let i in getValues()?.reference[phnIndex]?.phone) {
        if (getValues()?.reference[phnIndex]?.phone[i]?.text?.trim() != "") {
          if (!isValidPhoneNumber(getValues()?.reference[phnIndex]?.phone[i]?.text)) {
            flag = false
            setError(
              `reference[${phnIndex}].phone[${i}].text`,
              {
                type: "manual",
                message:  t("pleaseEnterAValidPhoneNumber",{defaultValue:"Please enter a valid phone number"}),
              },
              { shouldFocus: true }
            );
          }
        } else {
          flag = false
          setError(
            `reference[${phnIndex}].phone[${i}].text`,
            {
              type: "manual",
              message: t("pleaseEnterPhoneNumber",{defaultValue:"Please enter phone number"}),
            },
            { shouldFocus: true }
          );
        }
      }
      for (let j = 0; j < getValues()?.reference[phnIndex]?.phone?.length; j++) {
        for (let k = j + 1; k < getValues()?.reference[phnIndex]?.phone?.length; k++) {
          if (getValues()?.reference[phnIndex]?.phone[j].text == getValues()?.reference[phnIndex]?.phone[k]?.text) {
            flag = false
            setError(
              `reference[${phnIndex}].phone[${k}].text`,
              {
                type: "manual",
                message: t("phonenumberIsAlreadyPresent",{defaultValue:"Phonenumber is already present"}),
              },
              { shouldFocus: true }
            );
          } else {
            clearErrors(
              `reference[${phnIndex}].phone[${k}].text`
            );
          }
        }
      }
    }
    if (flag) {
      append({
        text: "",
      });
    }
  }
  return (
    <>
      <div className="form-group">
        <label>
          {t("phoneNumber", { defaultValue:"Phone Number" })}
        </label>
        {fieldObj?.required && <span className="text-danger">{"*"}</span>}
        {fields.map((item, index) => {
          return (
            <>
              <div className="my-2 d-flex" >
                <Controller
                  key={item?.id}
                  control={control}
                  name={`reference[${phnIndex}].phone[${index}].text`}
                  refs={register(
                    `reference[${phnIndex}].phone[${index}].text`,
                    {
                      required: fieldObj?.required ? true : false,
                      pattern: {
                        value: /^[^\s]+(?:$|.*[^\s]+$)/,
                        message: t("enteredValueCantStart/EndOrContainOnlyWhiteSpacing",{defaultValue:"Entered value cant start/end or contain only white spacing"}),
                      },
                    }
                  )}
                  rules={{
                    required: fieldObj?.required ? true : false,
                    validate: (value) => {
                      if (value) {
                        return isValidPhoneNumber(String(value));
                      }
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput

                      international
                      defaultCountry={"US"}
                      initialValueFormat="national"
                      countryCallingCodeEditable={false}
                      limitMaxLength={true}
                      // autoFocus="autoFocus"
                      inputextraprops={{
                        required: true,
                        autoFocus: true,
                      }}
                      className="from-control"
                      disabled={fieldObj?.editable ? false : true}
                      placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(fieldObj?.i18nKey, { defaultValue: fieldObj?.fieldName || "Phone Number" })}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        handlePhonenumber(val, index, phnIndex)
                      }
                      }
                    />
                  )}
                />

               {fieldObj?.editable && <span
                  className="px-2 IconArea actionIcon actionIcon-sm"
                  onClick={() => {
                    remove(index);
                    handlePhoneNumberChange()
                  }}
                >
                  <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                </span>}
              </div>
              <div>
                {errors?.["reference"]?.[phnIndex]?.["phone"]?.[index]?.[
                  "text"
                ].type === "validate" && (
                    <span className="text-danger">
                      <Trans
                        i18nKey="pleaseEnterValidMobileNumber"
                        defaults="Please Enter Valid Mobile Number"
                      />
                    </span>
                  )}
                {errors?.["reference"]?.[phnIndex]?.["phone"]?.[index]?.[
                  "text"
                ].type === "required" ? (
                  <>
                    <span className="text-danger">

                      <Trans
                        i18nKey="phoneNumberIsRequired"
                        defaults="Phone Number Is Required"
                      />
                    </span>
                  </>
                ) : (
                  <>
                    <span className="text-danger">
                      {
                        errors?.["reference"]?.[phnIndex]?.["phone"]?.[index]?.[
                          "text"
                        ]?.message ? errors?.["reference"]?.[phnIndex]?.["phone"]?.[index]?.[
                          "text"
                        ]?.message : ""
                      }
                    </span>
                  </>
                )}
              </div>
            </>
          );
        })}
        { fieldObj?.editable && 
        <div className="row my-3">
          <div className="col-sm-12 col-md-12">
            <div className="d-flex justify-content-center">
              <span
                className="px-2 IconArea actionIcon actionIcon-sm"
                onClick={
                  handlePhnAdd
                }
              >
                <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
              </span>
            </div>
            <div className="d-flex justify-content-center">
              <span>

                <Trans
                  i18nKey="addPhoneNumber"
                  defaults="Add phone number"
                />
              </span>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
};

export default ReferencePhonesForm;
