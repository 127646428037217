import React, { useEffect, useState, useContext } from "react";
import { Modal, Uploader, useToaster, Notification } from "rsuite";
import { Trans, useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";
// import { useUploadProfileResumesMutation } from "../../services/resume/resumeDetailsService";
// import { useUserDetails } from "../../app/customHooks/UseUserDetails";
import { Button } from "react-bootstrap";
function UploadResumeModal({
  data: resumeData,
  showUploadResumeModal,
  setShowUploadResumeModal,
  setResume,
}) {
  const uploader = React.createRef();
  const [uploadedFile, setUploadedFile] = useState([]);
  const [profileResumeStatus, setProfileResumeStatus] = useState(false);
  const [fileID, setFileID] = useState("");
  const {
    post: uploadResumes,
    data: uploadresumeData,
    isLoading,
    error: uploadresumeError,
  } = usePost("/user/uploadProfileResumes");
  const toaster = useToaster();
  const { t } = useTranslation();
  const handleUploadResumeClose = () => {
    setShowUploadResumeModal(false);
    setProfileResumeStatus(false);
    setUploadedFile([]);
    setFileID("");
    clearErrors("UploadProfile");
  };

  const {
    register,
    control,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
    getValues,
  } = useForm();

  const fileOnChange = (e) => {
    if (e.length > 1) {
      setUploadedFile([e[e.length - 1]]);
    } else {
      setUploadedFile(e);
    }
  };
  function getBase64File(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(";base64,")[1]);
      reader.onerror = (error) => reject(error);
    });
  }
  const { user, setUserDetails } = useContext(UserContext);
  // console.log(resumeData, "kktyuio");
  const uploadProfile = async () => {
    // if (uploadedFile[0]?.blobFile && uploadedFile[0]?.blobFile.size <= 2e6) {
    //   if (acceptedFileTypes.includes(fileType)) {
    //     let fileInfo = {};
    //     let base64Files = [];
    //     base64Files.push({
    //       originalname: file.name,
    //       size: file.blobFile.size,
    //       type: "docFile",
    //       path: "",
    //       base64File: await getBase64File(file.blobFile),
    //     });
    //     fileInfo["base64Files"] = base64Files;
    //     fileInfo["resumeID"] = resumeData?._id;
    //     fileInfo["fileName"] = uploadedFile[0]?.name;
    //     fileInfo["fileID"] = fileID ? fileID : "";
    //     (fileInfo["companyID"] = user?.companyID),
    //       (fileInfo["clientID"] = resumeData?.clientID),
    //       (fileInfo["userID"] = user?._id);
    //     uploadResumes(fileInfo)
    //       .then((resp) => {
    //         console.log("response", resp);

    //         if (resp?.status) {
    //           if (resp?.result?.status && resp.result.status === true) {
    //             handleUploadResumeClose();
    //           } else {
    //             toaster.push(
    //               <Notification
    //                 type={"error"}
    //                 header={t("error", { defaultValue: "Error" })}
    //                 closable
    //               >
    //                 {t("somethingWentWrongPleaseTryAgainLater", {
    //                   defaultValue:
    //                     "Something went wrong please try again later",
    //                 })}
    //               </Notification>,
    //               { placement: "topEnd" }
    //             );
    //           }
    //         } else {
    //           toaster.push(
    //             <Notification
    //               type={"error"}
    //               header={t("error", { defaultValue: "Error" })}
    //               closable
    //             >
    //               {t("somethingWentWrongPleaseTryAgainLater", {
    //                 defaultValue: "Something went wrong please try again later",
    //               })}
    //             </Notification>,
    //             { placement: "topEnd" }
    //           );
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log("error", error)
    //       });
    //   } else {
    //     setError("UploadProfile", {
    //       types: {
    //         formatError:
    //           "Please upload the accepted file formats (doc, docx, pdf, rtf, txt, odt, ott, htm, html, ods, xls, xlsx, csv)",
    //       },
    //     });
    //   }
    // } else {
    //   setError("UploadProfile", {
    //     types: { sizeError: "Please upload a file less than 2MB" },
    //   });
    //   if (!acceptedFileTypes.includes(fileType)) {
    //     setError("UploadProfile", {
    //       types: {
    //         sizeError: "Please upload a file less than 2MB",
    //         formatError:
    //           "Please upload the accepted file formats (doc, docx, pdf, rtf, txt, odt, ott, htm, html, ods, xls, xlsx, csv)",
    //       },
    //     });
    //   }
    // }
    if (getValues()?.UploadProfiles?.length <= 1) {
      let fileType = uploadedFile[0]?.blobFile.name.split(".").pop().toLowerCase();
      let acceptedFileTypes = [
        "doc",
        "docx",
        "pdf",
        "rtf",
        "txt",
        "odt",
        "ott",
        "htm",
        "html",
        // "ods",
        "xls",
        "xlsx",
        "csv",
      ];
      if (uploadedFile[0]?.blobFile && uploadedFile[0]?.blobFile.size <= 2e6) {
        if (acceptedFileTypes.includes(fileType)) {
          let fileInfo = {};
          fileInfo["base64"] = await getBase64File(uploadedFile[0]?.blobFile);
          fileInfo["resumeID"] = resumeData?._id;
          fileInfo["companyID"] = user?.companyID;
            (fileInfo["articleID"] =
              user?.serviceSettings?.resumeParser?.defaultArticleID);
          fileInfo["fileName"] = uploadedFile[0]?.name;
          fileInfo["fileID"] = fileID ? fileID : "";
          fileInfo["userID"] = user?._id;
          uploadResumes(fileInfo).then((resp) => {
            // console.log(resp?.result);
            if (resp?.status) {
              if (resp?.result?.status) {
                setResume(resp?.result?.resumeDetails);
                handleUploadResumeClose();
                toaster.push(
                  <Notification
                    type={"success"}
                    header={t("success", { defaultValue: "success" })}
                    closable
                  >
                    {t("resumeUplodedSuccessfully", {
                      defaultValue: "Resume Uploded Successfully",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
              } else {
                handleUploadResumeClose();
                toaster.push(
                  <Notification
                    type={"error"}
                    header={t("error", { defaultValue: "Error" })}
                    closable
                  >
                    {t("somethingWentWrongPleaseTryAgainLater", {
                      defaultValue:
                        "Something went wrong.Please try again later",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
              }
            } else {
              handleUploadResumeClose();
              toaster.push(
                <Notification
                  type={"error"}
                  header={t("error", { defaultValue: "Error" })}
                  closable
                >
                  {t("somethingWentWrongPleaseTryAgainLater", {
                    defaultValue: "Something went wrong.Please try again later",
                  })}
                </Notification>,
                { placement: "topEnd" }
              );
            }
          });
        } else {
          setError("UploadProfile", {
            types: {
              formatError:
                "Please upload the accepted file formats (doc, docx, pdf, rtf, txt, odt, ott, htm, html, xls, xlsx, csv)",
            },
          });
        }
      } else {
        toaster.push(
          <Notification
            type={"error"}
            header={t("error", { defaultValue: "Error" })}
            closable
          >
            {t("uploadingFilesLimitIs", {
              defaultValue: "Uploading files limit is 1",
            })}
          </Notification>,
          { placement: "topEnd" }
        );
      }
    }
  };
  return (
    <>
      {showUploadResumeModal && (
        <Modal
          size={"md"}
          overflow={false}
          open={showUploadResumeModal}
          onClose={handleUploadResumeClose}
        >
          <Modal.Header>
            <Trans i18nKey="uploadResume" defaults="Upload Resume" />
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="d-flex justify-content-center text-center">
                <Controller
                  control={control}
                  name={"UploadProfiles"}
                  refs={register("UploadProfiles", {
                    required: true,
                  })}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Uploader
                      action=""
                      draggable={isLoading ? false : true}
                      autoUpload={false}
                      onChange={(e) => {
                        onChange(e);
                        fileOnChange(e);
                      }}
                      accept=".doc, .docx, .pdf, .rtf, .txt, .odt, .ott, .htm, .html, .xls, .xlsx, .csv"
                      fileListVisible={true}
                      ref={uploader}
                      fileList={uploadedFile}
                    >
                      <div
                        style={{
                          height: 165,
                          width: 500,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <div>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 1000 1000"
                              fill={"var(--bs-primary)"}
                              height="35"
                              width="50"
                            >
                              <metadata>
                                {
                                  "Svg Vector Icons : http://www.onlinewebfonts.com/icon"
                                }
                              </metadata>
                              <g>
                                <path d="M850,974.5H150c-77.3,0-140-65.3-140-145.9V646.3c0-20.1,15.7-36.5,35-36.5h70c19.3,0,35,16.3,35,36.5v109.4c0,40.3,31.3,72.9,70,72.9h560c38.7,0,70-32.7,70-72.9V646.3c0-20.1,15.7-36.5,35-36.5h70c19.3,0,35,16.3,35,36.5v182.3C990,909.2,927.3,974.5,850,974.5L850,974.5z M784.5,449.2c-14.2,14.8-37.1,14.8-51.3,0L570,279.1v367.2c0,20.1-15.7,36.5-35,36.5h-70c-19.3,0-35-16.3-35-36.5V279.1L266.8,449.2c-14.2,14.8-37.1,14.8-51.3,0l-51.3-53.4c-14.2-14.8-14.2-38.7,0-53.4L453.2,41.1c1.2-1.3,23.7-15.6,46.4-15.6c22.9,0,45.9,14.3,47.2,15.6l289.1,301.2c14.2,14.8,14.2,38.7,0,53.4L784.5,449.2L784.5,449.2z" />
                              </g>
                            </svg>
                          </div>
                          <div className="my-1  mb-2">
                            <Trans
                              defaults="Drag & Drop Profile Here"
                              i18nKey="dragAndDropProfile"
                            />
                          </div>
                          <div className="my-1">
                            <Trans defaults="or" i18nKey="or" />
                          </div>
                          <div>
                            <Button variant="primary" className="btn-sm">
                              <Trans
                                defaults="Browse Profiles"
                                i18nKey="browseProfiles"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Uploader>
                  )}
                />
              </div>
              <div className="d-flex justify-content-center text-center my-2">
                <Trans
                  defaults="We accept only doc, docx, pdf, rtf, txt, odt, ott, htm, html, xls, xlsx, and csv max file size up to: 2 MB"
                  i18nKey="formatSizeWarningMessage"
                />
              </div>
              <div>
                {errors?.UploadProfile &&
                  errors?.UploadProfile?.type == "required" && (
                    <span className="text-danger">
                      <Trans
                        defaults="Please upload a profile"
                        i18nKey="pleaseUploadAProfile"
                      />
                    </span>
                  )}
                {errors?.UploadProfile && errors?.UploadProfile.types && (
                  <p className="text-danger">
                    {errors.UploadProfile.types.formatError}
                  </p>
                )}
                {errors?.UploadProfile && errors?.UploadProfile.types && (
                  <p className="text-danger">
                    {errors.UploadProfile.types.sizeError}
                  </p>
                )}
                {errors?.UploadProfile &&
                  (errors?.UploadProfile?.type == "formatError" ||
                    errors?.UploadProfile?.type == "sizeError") && (
                    <span className="text-danger">
                      {errors?.UploadProfile?.message}
                    </span>
                  )}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between">
              <Button
                className="btn-sm"
                variant="light"
                onClick={handleUploadResumeClose}
              >
                <Trans i18nKey="cancel" defaults="Cancel" />
              </Button>
              <Button
                variant="primary"
                disabled={isLoading}
                className="btn-sm"
                onClick={handleSubmit(uploadProfile)}
              >
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm ms-1"
                    role="status"
                    aria-hidden="true"
                  ></div>
                ) : (
                  <></>
                )}
                <Trans i18nKey="upload" defaults="Upload" />
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default UploadResumeModal;
