import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { useGetResumeDetailsQuery } from "../../../services/resume/resumeDetailsService";
import { Controller } from "react-hook-form";
import { t } from "i18next";
import { Trans } from "react-i18next";

import { Popover, Tooltip, Whisper, List, Checkbox, Placeholder } from "rsuite";

import usePost from "../../../customhooks/UsePost";
import { useLoaderData } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";

import {
  faMinusCircle,
  faPlusCircle,
  faXmark,
  faRotateRight,
  faCircleDot,
  faInfoCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

const SummaryFormField = ({
  summaryData,
  control,
  register,
  getValues,
  setValue,
  errors,
  type,
  resumeData,
  setshowapprove,
  showGenerateButton
}) => {
  useEffect(() => {
    if (summaryData) {
      setValue("Summary", summaryData || "");
    }
  }, [summaryData]);
  const loaderDetails = useLoaderData();
  const {
    post: generatedescription,
    data: generatedescriptionData,
    isLoading: generatedescriptionLoading,
    error: generatedescriptionError,
  } = usePost("/user/generateSummaryDescription");
  const [selectedText, setSelectedText] = useState("");
  const triggerContactDetailsRef = React.useRef();
  const triggerRef = React.useRef();
  const [aiSuggestions, setAISuggestions] = useState([]);
  const [aiSuggestionsPara, setAISuggestionsPara] = useState();
  const [aiRewriteSuggetions, setAIRewriteSuggestions] = useState([]);
  const [aiRewriteSuggetionsPara, setAIRewriteSuggestionsPara] = useState("");
  const [selectedTextCount, setSelectedTextCount] = useState(0);
  const [resumedatacomplete, setresumedatacomplete] = useState(false);
  const [selectedItems, setSelectedItems] = useState(getValues().Summary || "");
  const [rewriteCount, setRewriteCount] = useState(0);
  const [generateCount, setGenerateCount] = useState(0);
  const [generateType, setGenerateType] = useState(
    getValues().Summary && getValues().Summary?.includes("•")
      ? "points"
      : "paragraph"
  );

  const {
    post: generatedescrewrite,
    data: generatedescrewritedata,
    isLoading: generatedescrewriteloading,
    error: generatedescrewriteerror,
  } = usePost("/user/generatedescrewrite");

  const handlerewrite = (data) => {
    // console.log(data);
    const data3 = {
      selectedLine: data,
      rewriteFor: "summary",
      resumeID: resumeData?._id,
      companyID:
        loaderDetails?.companyID ?? loaderDetails?.jobDetails?.companyID,
      generateFor: generateType,
    };
    setRewriteCount(rewriteCount + 1);
    generatedescrewrite(data3)
      .then((res) => {
        if (res?.status) {
          if (generateType === "points") {
            setAIRewriteSuggestions(res?.result?.descriptionPoints);
            setAISuggestions([]);
            setAISuggestionsPara([]);
            setAIRewriteSuggestionsPara("");
          } else {
            setAIRewriteSuggestionsPara(res?.result?.paragraphs);
            setAISuggestions([]);
            setAISuggestionsPara([]);
            setAIRewriteSuggestions([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [showText, setShowText] = useState(getValues().Summary || "");
  const popOverOpen = () => {
    triggerContactDetailsRef?.current?.open();
  };
  const PopOverclose2 = () => {
    triggerRef?.current?.close();
  };
  const popOverClose = () => {
    triggerContactDetailsRef?.current?.close();
    triggerRef?.current?.open();
  };
  useEffect(() => {
    if (getValues()?.Summary != undefined) {
      setSelectedItems(getValues()?.Summary ?? "");
      setShowText(getValues()?.Summary ?? "");
      setCurrentIndex(getValues()?.Summary?.length ?? 0);
      // setshowapprove(false)
    }
  }, []);
  const [currentIndex, setCurrentIndex] = useState(
    getValues()?.Summary?.length || 0
  );
  const handleGeneratedesc = (suggestionType) => {
    const data2 = {
      resumeID: resumeData?._id,
      rewriteFor: "summary",
      companyID:
        loaderDetails?.companyID ?? loaderDetails?.jobDetails?.companyID,
      experience: getValues()?.experience,
      education: getValues()?.education,
      skills: getValues()?.skills,
      generateFor: suggestionType,
    };
    setGenerateCount(generateCount + 1);
    generatedescription(data2)
      .then((res) => {
        if (res?.status) {
          if (suggestionType === "points") {
            setAISuggestionsPara([]);
            setAISuggestions(res?.result?.descriptionPoints);
          } else {
            setAISuggestions([]);
            setAISuggestionsPara(res?.result?.paragraphs);
          }
          // console.log(res?.result?.descriptionPoints);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // console.log(getValues(), "getttvvvaluees");
    if (
      // resumeData?.resume?.professionalQualification?.education.length > 0 &&
      resumeData?.resume?.professionalQualification?.skills.length > 0 &&
      resumeData?.resume?.professionalExperience.length > 0
    ) {
      setresumedatacomplete(true);
    } else if (
      getValues()?.experience?.length > 0 &&
      // getValues()?.education?.length > 0 &&
      getValues()?.skills?.length > 0
    ) {
      setresumedatacomplete(true);
    } else {
      setresumedatacomplete(false);
    }
  }, [resumeData, getValues()]);
  const [rewritediv, setrewritediv] = useState(false);

  const handleTextSelection = () => {
    const textareaValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    // Find the boundaries of the selected word
    let wordStart = selectionStart;
    let wordEnd = selectionEnd;
    while (wordStart > 0 && textareaValue[wordStart - 1] !== "\n") {
      wordStart--;
    }
    while (wordEnd < textareaValue.length && textareaValue[wordEnd] !== "\n") {
      wordEnd++;
    }

    // Get the selected word
    const selectedWord = textareaValue.substring(wordStart, wordEnd);

    // Find the boundaries of the line containing the selected word
    let lineStart = textareaValue.lastIndexOf("\n", selectionStart - 1) + 1;
    let lineEnd = textareaValue.indexOf("\n", selectionEnd);
    if (lineEnd === -1) {
      lineEnd = textareaValue.length;
    }

    // Get the entire line containing the selected word
    const selectedLine = textareaValue.substring(lineStart, lineEnd);

    // Now you have the entire line containing the selected word
    console.log("Line containing the selected word:", selectedLine);
    setrewritediv(false);
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();
    setSelectedText(selectedLine);
    // const lineHeight = parseInt(
    //   window.getComputedStyle(textAreaRef.current).lineHeight
    // );
    // const lines = getValues(`experience[${expIndex}].description`).split("\n");
    // const targetIndex = lines.findIndex((line) => line.trim() === selectedText);
    // const height = lineHeight * (targetIndex + 1); // +1 because array index starts from 0
    // setDivPosition(height+20);
  };
  
  

  const handleaddrewrite = (point) => {
    // if(type !="customLayout"){
    setshowapprove(true);
    // }
    // console.log(point);
    const description = getValues().Summary;

    if (description.includes(selectedText)) {
      const updatedDescription = description.replace(
        selectedText,
        `• ${point}`
      );
      // Now you can update the description in your form state
      setValue(`Summary`, updatedDescription);
      setrewritediv(false);
      setSelectedText("");
      setShowText(getValues().Summary);
    }
  };

  const handleCheckboxChange = (item) => {
    // if(type !="customLayout"){
    setshowapprove(true);
    setGenerateType("points");
    // }
    // console.log(selectedItems);
    if (selectedTextCount === 1 && !(selectedItems?.includes("•"))) {
      setCurrentIndex(0);
      setSelectedItems(`• ${item}\n`);
      setShowText("");
    } else {
      if (selectedItems?.includes(item)) {
        setShowText(selectedItems?.replace(`• ${item}\n`, ""));
        setSelectedItems(selectedItems?.replace(`• ${item}\n`, ""));
        setCurrentIndex(selectedItems?.replace(`• ${item}\n`, "")?.length);
      } else {
        let str = selectedItems?.replace(/^\n+/, "").replace(/\n{2,}/g, "\n");
        if (str != "" && !str.endsWith("\n")) {
          setSelectedItems(`${str}\n• ${item}\n`);
          setCurrentIndex(showText?.length);
        } else {
          setSelectedItems(`${str}• ${item}\n`);
          setCurrentIndex(showText?.length);
        }
      }
    }
  };
  const handleAddPara = (para) => {
    setGenerateType("paragraph");
    setshowapprove(true);
    setSelectedItems(para);
    setShowText(para);
    setCurrentIndex(para?.length);
    setrewritediv(false);
    setSelectedText("");
  };
  useEffect(() => {
    if (selectedItems === "") {
      setshowapprove(false);
    }
  }, [selectedItems, currentIndex]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIndex < selectedItems?.length) {
        setShowText((prevText) => prevText + selectedItems[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(timer);
      }
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex, selectedItems]);
  useEffect(() => {
    setValue(`Summary`, showText?.replace(/^\n+/, ""));
    setShowText(showText?.replace(/^\n+/, ""));
  }, [showText]);

  useEffect(() => {
    if (rewritediv) {
      // setTimeout(() => {
      const selectedLi = document.querySelector(".seclecdrewritetext");
      if (selectedLi) {
        // console.log("Scrolling into view:", selectedLi);

        selectedLi.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      // }, 200);
    }
  }, [rewritediv]);

  const [descriptionWidth, setDescriptionWidth] = useState(0);
  // console.log(getValues(), "djjjjjjjjjjjjjjjjjjj");
  useEffect(() => {
    const descriptionElements = document.querySelectorAll(
      ".descriptiontextbox"
    );
    let maxWidth = 0;

    descriptionElements.forEach((element) => {
      const width = element.getBoundingClientRect().width;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    setDescriptionWidth(maxWidth);
  }, [getValues()]);

  const handleFocus = () => {
    if (getValues().Summary === "" && generateType !== "paragraph") {
      setValue("Summary", "• ");
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action (new line)
      if (
        getValues().Summary !== undefined &&
        getValues().Summary !== "undefined" &&
        generateType !== "paragraph"
      ) {
        const start = event.target.selectionStart;
        const end = event.target.selectionEnd;

        const currentText = getValues().Summary || "";
        const updatedText =
          currentText.substring(0, start) + "• " + currentText.substring(end);

        setValue("Summary", updatedText, {
          shouldValidate: true,
          shouldDirty: true,
        });

        // Set cursor position after the bullet point
        setTimeout(() => {
          event.target.selectionStart = event.target.selectionEnd = start + 2;
        }, 0);
      }
    }
  };

  // const handleChange = (event) => {
  //   setTodo(event.target.value);
  // };
  return (
    <>
      <div className="form-group position-relative descriptiontextbox">
      {showGenerateButton &&<div className="d-flex justify-content-end mb-2 mt-2">
          {getValues()?.Summary?.length > 0 && (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={
                <Tooltip>
                  Click on the existing line to rewrite or create a new line to
                  generate a new sentence
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="me-3 text-primary mt-2"
                size="lg"
              />
            </Whisper>
          )}
          {!resumedatacomplete && (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={
                <Tooltip>
                  Update your Skills, Experience to generate your
                  Profile Summary. Alternatively, you can manually enter the
                  information.
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="me-3 mt-2 text-warning"
                size="lg"
              />
            </Whisper>
          )}
          {selectedText && selectedText != "" ? (
            <>
              {rewriteCount >= 5 ? (
                <>
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={<Tooltip>You can rewrite only 5 times</Tooltip>}
                  >
                    <button
                      className="rounded-pill"
                      style={{
                        backgroundImage:
                          "linear-gradient(to bottom right, #484b4e, #484b4e)",
                        padding: "5px 15px" 
                      }}
                      disabled
                    >
                      <span className="text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          class="icon-sm shrink-0"
                        >
                          <path
                            fill="currentColor"
                            d="M6.394 4.444c.188-.592 1.024-.592 1.212 0C8.4 8.9 9.1 9.6 13.556 10.394c.592.188.592 1.024 0 1.212C9.1 12.4 8.4 13.1 7.606 17.556c-.188.592-1.024.592-1.212 0C5.6 13.1 4.9 12.4.444 11.606c-.592-.188-.592-1.024 0-1.212C4.9 9.6 5.6 8.9 6.394 4.444m8.716 9.841a.41.41 0 0 1 .78 0c.51 2.865.96 3.315 3.825 3.826.38.12.38.658 0 .778-2.865.511-3.315.961-3.826 3.826a.408.408 0 0 1-.778 0c-.511-2.865-.961-3.315-3.826-3.826a.408.408 0 0 1 0-.778c2.865-.511 3.315-.961 3.826-3.826Zm2.457-12.968a.454.454 0 0 1 .866 0C19 4.5 19.5 5 22.683 5.567a.454.454 0 0 1 0 .866C19.5 7 19 7.5 18.433 10.683a.454.454 0 0 1-.866 0C17 7.5 16.5 7 13.317 6.433a.454.454 0 0 1 0-.866C16.5 5 17 4.5 17.567 1.317"
                          ></path>
                        </svg>
                      </span>
                      <span className="mx-2 text-white">AI Rewrite</span>
                    </button>
                  </Whisper>
                </>
              ) : (
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>Click here to rewrite the sentence
                    </Tooltip>
                  }
                >
                  <span className="sparkle-button ms-1">
                    <button
                      className="gen animated-button-shake"
                      // style={{
                      //   backgroundImage:
                      //     rewriteCount >= 5 &&
                      //     "linear-gradient(to bottom right, #484b4e, #484b4e)",
                      //   // : "linear-gradient(to bottom right, rgb(79 31 202), #ff0089)",
                      // }}
                      // disabled={rewriteCount >= 5}
                      onClick={() => {
                        setrewritediv(true);
                        handlerewrite(selectedText);
                      }}
                    >
                      <span className="spark"></span>
                      {/* <!-- <span className="spark"></span> --> */}
                      <span className="backdrop"></span>
                      <svg
                        className="sparkle"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                          fill="black"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                          fill="black"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                          fill="black"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="text">AI Rewrite</span>
                    </button>
                  </span>
                  {/* <button
                className="animated-button1 animated-button-shake  rounded-pill"
                style={{
                  backgroundImage:
                    rewriteCount >= 5
                      ? "linear-gradient(to bottom right, #484b4e, #484b4e)"
                      : "linear-gradient(to bottom right, rgb(79 31 202), #ff0089)",
                }}
                disabled={rewriteCount >= 5}
                onClick={() => {
                  setrewritediv(true);
                  handlerewrite(selectedText);
                }}
              >
                AI Rewrite
                <span className=" ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_2"
                    data-name="Layer 2"
                    viewBox="0 0 443.4593 433.0255"
                    width="23"
                    height="30"
                    fill="currentColor"
                  >
                    <g id="katman_1" data-name="katman 1">
                      <g id="aeeahZ">
                        <g>
                          <path d="m131.5155,359.85c-2.6118-18.9681-5.8553-37.7077-10.0856-56.3222-8.0135-35.2613-29.6706-55.9642-64.6619-63.7553-16.3282-3.6356-32.737-6.641-49.2873-8.9642-1.8977-.2664-3.7876-.5881-7.4807-1.1657,19.3475-2.8209,36.7579-5.9282,54.1295-9.3946,39.0754-7.7975,61.2177-31.5229,68.7905-70.1149,3.2748-16.6886,6.4042-33.3803,9.2335-50.3929.8188,5.4269,1.6407,10.8533,2.4558,16.2807,2.0347,13.5485,4.8393,26.9297,7.8862,40.2949,7.9022,34.6626,29.2623,55.1752,63.7116,63.0406,18.4941,4.2225,37.1449,7.3563,56.6946,10.1062-15.2917,2.6606-29.8143,4.9892-44.2533,7.7546-14.837,2.8416-29.3253,6.9138-42.4802,14.6757-19.0331,11.2302-27.7288,29.4478-33.2407,49.7138-4.9082,18.0461-7.7508,36.489-10.2186,54.9963-.1455,1.0915.3855,2.4778-1.1934,3.2468Z" />
                          <path d="m443.4593,141.6159c-21.1133,2.8584-40.5154,6.1332-59.7034,10.8347-33.2363,8.1437-53.0303,28.9442-60.8957,62.0086-4.4578,18.7394-7.595,37.6752-10.5353,58.7298-1.7795-11.2311-3.2132-20.5291-4.7332-29.8129-2.0446-12.4874-4.8889-24.7827-8.4132-36.9492-8.8497-30.5505-30.1733-47.0445-60.0674-54.3655-18.5934-4.5535-37.4106-7.7217-56.4854-10.9459,7.4104-1.129,14.8421-2.1354,22.2272-3.4111,18.9916-3.2805,37.9869-6.5046,55.7475-14.5972,23.3525-10.6405,34.4008-30.4124,40.6503-53.9059,4.6539-17.4952,7.4392-35.331,9.9607-53.2209.2048-1.4529.5017-2.8929,1.0924-6.2546,2.7523,19.2104,5.9016,36.3689,9.2343,53.5128,7.9956,41.1311,33.6829,62.8722,73.7873,69.9972,14.8223,2.6333,29.5905,5.5354,44.5366,7.4065.6968.0872,1.37.3625,3.5973.9734Z" />
                          <path d="m172.6161,340.602c10.1042-1.7813,20.2391-3.8765,30.4663-5.3149,34.9741-4.9192,52.0441-26.4598,56.543-60.0393,1.1178-8.3432,2.7167-16.622,4.4052-26.8038,1.3904,8.5289,2.4574,15.3339,3.6167,22.1231,1.6741,9.8041,3.9477,19.4488,7.3036,28.843,6.9543,19.4678,22.0277,28.9454,41.0131,33.7313,11.5499,2.9115,23.2471,5.0131,35.0624,6.4923,1.432.1793,2.8376.5688,4.5622.9241-11.0758,2.0233-21.6744,4.2124-32.3563,5.8631-31.6075,4.8844-48.8375,23.6908-53.7906,54.7294-1.6005,10.0296-3.4881,20.0133-5.5703,31.8753-1.9173-13.801-4.1769-25.6569-6.525-37.5057-5.631-28.4149-23.3001-43.82-51.1064-48.8231-11.2092-2.0169-22.4134-4.0618-33.6237-6.0946Z" />
                          <path d="m195.4665,122.0123c-1.7604-9.1527-3.2575-16.4901-4.5725-23.86-3.5291-19.7786-15.8767-30.3087-35.1044-33.7115-6.9255-1.2257-13.8476-2.4712-22.3205-3.9844,10.928-1.725,20.3016-3.1867,29.4945-5.7067,14.4909-3.9723,23.1449-13.2979,26.9064-27.6322,2.2773-8.6783,3.675-17.521,5.9437-27.1174.0014,40.3169,20.3557,59.8161,59.7355,60.7762-2.1751.3514-4.3521.6907-6.5246,1.0573-2.376.401-4.7587.7732-7.1198,1.2506-32.1817,6.5072-40.3869,15.2955-44.8046,47.9237-.4182,3.0885-.9131,6.1666-1.6339,11.0044Z" />
                          <path d="m31.5367,97.5445c17.0948-.5338,25.6834-9.0756,26.047-26.0664,5.0607,21.2407,6.3381,22.5048,26.5162,26.2613-16.5491.5794-25.7536,8.4699-26.1523,26.0598-.7542-17.1191-9.2678-25.7721-26.4109-26.2546Z" />
                          <path d="m365.1106,261.3267c16.8747-.6835,24.8921-9.3279,25.7721-25.8408.8113,16.8948,9.3067,25.2286,26.1985,25.8242-16.4305.8863-25.6678,8.5635-26.1307,25.9574-.764-16.8522-9.3144-25.2081-25.8399-25.9407Z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </button> */}
                </Whisper>
              )}
            </>
          ) : (
            <>
            { (!resumedatacomplete || generateCount >= 5) ? (
              <>
                <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={<Tooltip>You can generate only 5 times</Tooltip>}
                  >
                    <button
                      className="rounded-pill"
                      style={{
                        backgroundImage:
                          "linear-gradient(to bottom right, #484b4e, #484b4e)",
                        padding: "5px 15px" 
                      }}
                      disabled
                    >
                      <span className="text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          class="icon-sm shrink-0"
                        >
                          <path
                            fill="currentColor"
                            d="M6.394 4.444c.188-.592 1.024-.592 1.212 0C8.4 8.9 9.1 9.6 13.556 10.394c.592.188.592 1.024 0 1.212C9.1 12.4 8.4 13.1 7.606 17.556c-.188.592-1.024.592-1.212 0C5.6 13.1 4.9 12.4.444 11.606c-.592-.188-.592-1.024 0-1.212C4.9 9.6 5.6 8.9 6.394 4.444m8.716 9.841a.41.41 0 0 1 .78 0c.51 2.865.96 3.315 3.825 3.826.38.12.38.658 0 .778-2.865.511-3.315.961-3.826 3.826a.408.408 0 0 1-.778 0c-.511-2.865-.961-3.315-3.826-3.826a.408.408 0 0 1 0-.778c2.865-.511 3.315-.961 3.826-3.826Zm2.457-12.968a.454.454 0 0 1 .866 0C19 4.5 19.5 5 22.683 5.567a.454.454 0 0 1 0 .866C19.5 7 19 7.5 18.433 10.683a.454.454 0 0 1-.866 0C17 7.5 16.5 7 13.317 6.433a.454.454 0 0 1 0-.866C16.5 5 17 4.5 17.567 1.317"
                          ></path>
                        </svg>
                      </span>
                      <span className="mx-2 text-white">AI Generate</span>
                    </button>
                  </Whisper>
              </>):
            <Whisper
              placement="auto"
              trigger={generateCount >= 5 ? "hover" : "click"}
              ref={triggerContactDetailsRef}
              speaker={
                  <Popover
                    className="text-dark"
                    style={{
                      width: "350px",
                      height: "150px",
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <h5 className="m-0">
                        <Trans
                          i18nKey="selectOneOfThese"
                          defaults="Select One Of These"
                        />
                      </h5>
                    </div>
                    <List>
                      <List.Item
                        onClick={() => {
                          handleGeneratedesc("paragraph");
                          setSelectedTextCount(1);
                          popOverClose();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          <FontAwesomeIcon
                            className="me-1 bg-white text-primary"
                            icon={faPlusCircle}
                            size="lg"
                          ></FontAwesomeIcon>
                        </span>
                        <span>Let us write the summary (in a paragraph)</span>
                      </List.Item>
                      <List.Item
                        onClick={() => {
                          handleGeneratedesc("points");
                          setSelectedTextCount(1);
                          popOverClose();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          <FontAwesomeIcon
                            className="me-1 bg-white text-primary"
                            icon={faPlusCircle}
                            size="lg"
                          ></FontAwesomeIcon>
                        </span>
                        <span>
                          Build your summary (using the suggested points)
                        </span>
                      </List.Item>
                    </List>
                  </Popover>
              }
            >
              <span className="sparkle-button ms-1">
                <button
                  className="gen"
                  // style={{
                  //   backgroundImage:
                  //     (resumedatacomplete || !(generateCount >= 5)) &&
                  //     "linear-gradient(to bottom right, #484b4e, #484b4e)",
                  // }}
                  onClick={() => {
                    if (resumedatacomplete) {
                      popOverOpen();
                    }
                  }}
                  // disabled={
                  //   !resumedatacomplete || generateCount >= 5 ? true : false
                  // }
                >
                  <span className="spark"></span>
                  {/* <!-- <span className="spark"></span> --> */}
                  <span className="backdrop"></span>
                  <svg
                    className="sparkle"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                      fill="black"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                      fill="black"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                      fill="black"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="text">AI Generate</span>
                </button>
              </span>
              {/* <button
                className="animated-button1  rounded-pill"
                style={{
                  backgroundImage:
                    resumedatacomplete && !(generateCount >= 5)
                      ? "linear-gradient(to bottom right, rgb(79 31 202), #ff0089)"
                      : "linear-gradient(to bottom right, #484b4e, #484b4e)",
                }}
                onClick={() => {
                  if (resumedatacomplete) {
                    popOverOpen();
                  }
                }}
                disabled={
                  !resumedatacomplete || generateCount >= 5 ? true : false
                }
              >
                AI Generate
                <span className=" ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_2"
                    data-name="Layer 2"
                    viewBox="0 0 443.4593 433.0255"
                    width="23"
                    height="30"
                    fill="currentColor"
                  >
                    <g id="katman_1" data-name="katman 1">
                      <g id="aeeahZ">
                        <g>
                          <path d="m131.5155,359.85c-2.6118-18.9681-5.8553-37.7077-10.0856-56.3222-8.0135-35.2613-29.6706-55.9642-64.6619-63.7553-16.3282-3.6356-32.737-6.641-49.2873-8.9642-1.8977-.2664-3.7876-.5881-7.4807-1.1657,19.3475-2.8209,36.7579-5.9282,54.1295-9.3946,39.0754-7.7975,61.2177-31.5229,68.7905-70.1149,3.2748-16.6886,6.4042-33.3803,9.2335-50.3929.8188,5.4269,1.6407,10.8533,2.4558,16.2807,2.0347,13.5485,4.8393,26.9297,7.8862,40.2949,7.9022,34.6626,29.2623,55.1752,63.7116,63.0406,18.4941,4.2225,37.1449,7.3563,56.6946,10.1062-15.2917,2.6606-29.8143,4.9892-44.2533,7.7546-14.837,2.8416-29.3253,6.9138-42.4802,14.6757-19.0331,11.2302-27.7288,29.4478-33.2407,49.7138-4.9082,18.0461-7.7508,36.489-10.2186,54.9963-.1455,1.0915.3855,2.4778-1.1934,3.2468Z" />
                          <path d="m443.4593,141.6159c-21.1133,2.8584-40.5154,6.1332-59.7034,10.8347-33.2363,8.1437-53.0303,28.9442-60.8957,62.0086-4.4578,18.7394-7.595,37.6752-10.5353,58.7298-1.7795-11.2311-3.2132-20.5291-4.7332-29.8129-2.0446-12.4874-4.8889-24.7827-8.4132-36.9492-8.8497-30.5505-30.1733-47.0445-60.0674-54.3655-18.5934-4.5535-37.4106-7.7217-56.4854-10.9459,7.4104-1.129,14.8421-2.1354,22.2272-3.4111,18.9916-3.2805,37.9869-6.5046,55.7475-14.5972,23.3525-10.6405,34.4008-30.4124,40.6503-53.9059,4.6539-17.4952,7.4392-35.331,9.9607-53.2209.2048-1.4529.5017-2.8929,1.0924-6.2546,2.7523,19.2104,5.9016,36.3689,9.2343,53.5128,7.9956,41.1311,33.6829,62.8722,73.7873,69.9972,14.8223,2.6333,29.5905,5.5354,44.5366,7.4065.6968.0872,1.37.3625,3.5973.9734Z" />
                          <path d="m172.6161,340.602c10.1042-1.7813,20.2391-3.8765,30.4663-5.3149,34.9741-4.9192,52.0441-26.4598,56.543-60.0393,1.1178-8.3432,2.7167-16.622,4.4052-26.8038,1.3904,8.5289,2.4574,15.3339,3.6167,22.1231,1.6741,9.8041,3.9477,19.4488,7.3036,28.843,6.9543,19.4678,22.0277,28.9454,41.0131,33.7313,11.5499,2.9115,23.2471,5.0131,35.0624,6.4923,1.432.1793,2.8376.5688,4.5622.9241-11.0758,2.0233-21.6744,4.2124-32.3563,5.8631-31.6075,4.8844-48.8375,23.6908-53.7906,54.7294-1.6005,10.0296-3.4881,20.0133-5.5703,31.8753-1.9173-13.801-4.1769-25.6569-6.525-37.5057-5.631-28.4149-23.3001-43.82-51.1064-48.8231-11.2092-2.0169-22.4134-4.0618-33.6237-6.0946Z" />
                          <path d="m195.4665,122.0123c-1.7604-9.1527-3.2575-16.4901-4.5725-23.86-3.5291-19.7786-15.8767-30.3087-35.1044-33.7115-6.9255-1.2257-13.8476-2.4712-22.3205-3.9844,10.928-1.725,20.3016-3.1867,29.4945-5.7067,14.4909-3.9723,23.1449-13.2979,26.9064-27.6322,2.2773-8.6783,3.675-17.521,5.9437-27.1174.0014,40.3169,20.3557,59.8161,59.7355,60.7762-2.1751.3514-4.3521.6907-6.5246,1.0573-2.376.401-4.7587.7732-7.1198,1.2506-32.1817,6.5072-40.3869,15.2955-44.8046,47.9237-.4182,3.0885-.9131,6.1666-1.6339,11.0044Z" />
                          <path d="m31.5367,97.5445c17.0948-.5338,25.6834-9.0756,26.047-26.0664,5.0607,21.2407,6.3381,22.5048,26.5162,26.2613-16.5491.5794-25.7536,8.4699-26.1523,26.0598-.7542-17.1191-9.2678-25.7721-26.4109-26.2546Z" />
                          <path d="m365.1106,261.3267c16.8747-.6835,24.8921-9.3279,25.7721-25.8408.8113,16.8948,9.3067,25.2286,26.1985,25.8242-16.4305.8863-25.6678,8.5635-26.1307,25.9574-.764-16.8522-9.3144-25.2081-25.8399-25.9407Z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </button> */}
            </Whisper>}
            </>
          )}
        </div>}

        {!rewritediv ? (
          <div className="form-group">
            <Controller
              control={control}
              name={"Summary"}
              refs={register("Summary", {
                required: false,
              })}
              render={({ field: { onChange, onBlur, value } }) => (
                <Whisper
                  placement="auto"
                  trigger={"click"}
                  ref={triggerRef}
                  speaker={
                    <Popover
                      className="text-dark"
                      style={{
                        width: `${descriptionWidth}px`,
                        height: "180px",
                        overflowX: "hidden",
                        overflowY: "scroll",
                      }}
                    >
                      {!resumedatacomplete ? (
                        <>
                          Update your Skills, Experience to generate
                          your Profile Summary. Alternatively, you can manually
                          enter the information.
                        </>
                      ) : (
                        <>
                          {generatedescriptionLoading ? (
                            <Placeholder.Grid columns={2} active />
                          ) : (
                            <>
                              <div className="d-flex justify-content-between">
                                <span>
                                  <h5>
                                    <Trans
                                      i18nKey="suggestions"
                                      defaults="Suggestions"
                                    />
                                  </h5>
                                </span>
                                {/* {aiSuggestionsPara !== "" && (
                                  <span>
                                    <button
                                      className="bg-primary text-white rounded"
                                      onClick={() => {
                                        handleAddPara(aiSuggestionsPara);
                                        triggerRef.current.close();
                                      }}
                                    >
                                      use
                                    </button>
                                  </span>
                                )} */}
                              </div>
                              {aiSuggestions && aiSuggestions?.length > 0 ? (
                                <>
                                  <List>
                                    <>
                                      {aiSuggestions &&
                                      aiSuggestions?.length > 0 ? (
                                        <>
                                          {aiSuggestions?.map((point, i) => (
                                            <List.Item
                                              key={i}
                                              onClick={() => {
                                                setSelectedTextCount(
                                                  selectedTextCount + 1
                                                );
                                                handleCheckboxChange(point);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <span>
                                                {selectedItems?.includes(
                                                  point
                                                ) ? (
                                                  <>
                                                    <FontAwesomeIcon
                                                      className="me-1 bg-white text-primary"
                                                      icon={faMinusCircle}
                                                      size="lg"
                                                    ></FontAwesomeIcon>
                                                  </>
                                                ) : (
                                                  <>
                                                    <FontAwesomeIcon
                                                      className="me-1 bg-white text-primary"
                                                      icon={faPlusCircle}
                                                      size="lg"
                                                    ></FontAwesomeIcon>
                                                  </>
                                                )}
                                              </span>
                                              {point}
                                            </List.Item>
                                          ))}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  </List>
                                </>
                              ) : aiSuggestionsPara &&
                                aiSuggestionsPara?.length > 0 ? (
                                <>
                                  {aiSuggestionsPara?.map((point, i) => (
                                    <List.Item
                                      key={i}
                                      onClick={() => {
                                        // setSelectedTextCount(
                                        //   selectedTextCount + 1
                                        // );
                                        handleAddPara(point);
                                        triggerRef.current.close();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span>
                                        {selectedItems?.includes(point) ? (
                                          <>
                                            <FontAwesomeIcon
                                              className="me-1 bg-white text-primary"
                                              icon={faMinusCircle}
                                              size="lg"
                                            ></FontAwesomeIcon>
                                          </>
                                        ) : (
                                          <>
                                            <FontAwesomeIcon
                                              className="me-1 bg-white text-primary"
                                              icon={faPlusCircle}
                                              size="lg"
                                            ></FontAwesomeIcon>
                                          </>
                                        )}
                                      </span>
                                      {point}
                                    </List.Item>
                                  ))}
                                  {/* <div>
                                    <List>
                                      <List.Item>{aiSuggestionsPara}</List.Item>
                                    </List>
                                  </div> */}

                                  <div className="d-flex justify-content-end mt-2"></div>
                                </>
                              ) : (
                                <div className="d-flex justify-content-center my-3 text-muted">
                                  <Trans
                                    i18nKey="noSuggestions"
                                    defaults="No Suggestions"
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Popover>
                  }
                >
                  <textarea
                    onClick={PopOverclose2}
                    className="form-control"
                    name="Summary"
                    rows="10"
                    placeholder={
                      t("enter", { defaultValue: "Enter" }) +
                      " " +
                      t("summary", { defaultValue: "Summary" })
                    }
                    onChange={(value) => {
                      onChange(value);
                      setSelectedText("");
                      setShowText(getValues().Summary || "");
                      setSelectedItems(getValues().Summary);
                      setCurrentIndex(getValues().Summary.length);
                    }}
                    onFocus={handleFocus}
                    onKeyUp={handleKeyUp}
                    value={value}
                    onMouseUp={handleTextSelection}
                  />
                </Whisper>
              )}
            />
          </div>
        ) : (
          <div
            className="card"
            style={{ height: "150px", overflow: "scroll" }}
            onClick={() => {
              setSelectedText("");
              setrewritediv(false);
            }}
          >
            <div className="card-body">
              <ul
                className="ps-0"
                style={{ listStyleType: "none" }}
                // onMouseUp={handleTextSelection}
              >
                {getValues()
                  .Summary?.split("\n")
                  ?.map((x, i) => {
                    return (
                      <li
                        key={i}
                        // onClick={() => {
                        //   handleaddrewrite(point);
                        // }}
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          fontSize: "medium",
                        }}
                        className={`py-1 ${
                          selectedText.includes(x)
                            ? "text-primary seclecdrewritetext"
                            : "Unselectedrewritetext"
                        }`}
                      >
                        {x}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
        {rewritediv && (
          <div style={{ zIndex: "100", top: "130px" }}>
            <div className="card shadow-sm">
              <div className="card-header border-bottom d-flex align-items-center justify-content-between">
                <div>
                  <h6>Rewirte Suggestions</h6>
                </div>
                <div className="text-end d-flex py-2 justify-content-end">
                  {/* {aiRewriteSuggetionsPara !== "" && (
                    <span className="mx-2">
                      <button
                        className="bg-primary text-white rounded"
                        onClick={() => {
                          handleAddPara(aiRewriteSuggetionsPara);
                          // triggerRef.current.close();
                          setrewritediv(false);
                        }}
                      >
                        use
                      </button>
                    </span>
                  )} */}
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="lg"
                    className="text-muted cursorpointer"
                    onClick={() => {
                      setrewritediv(false);
                      setSelectedText("");
                    }}
                  />
                </div>
              </div>
              <div className="card-body">
                <>
                  {generatedescrewriteloading ? (
                    <Placeholder.Grid columns={5} active />
                  ) : (
                    <>
                      {aiRewriteSuggetions &&
                      aiRewriteSuggetions?.length > 0 ? (
                        <>
                          {aiRewriteSuggetions?.map((point, i) => (
                            <List.Item
                              key={i}
                              onClick={() => {
                                handleaddrewrite(point);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                <FontAwesomeIcon
                                  className="me-1 bg-white text-primary"
                                  icon={faCircleDot}
                                  size="lg"
                                ></FontAwesomeIcon>
                              </span>
                              {point}
                            </List.Item>
                          ))}
                        </>
                      ) : aiRewriteSuggetionsPara &&
                        aiRewriteSuggetionsPara?.length > 0 ? (
                        <>
                          {aiRewriteSuggetionsPara?.map((point, i) => (
                            <List.Item
                              key={i}
                              onClick={() => {
                                handleAddPara(point);
                                // triggerRef.current.close();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                <FontAwesomeIcon
                                  className="me-1 bg-white text-primary"
                                  icon={faCircleDot}
                                  size="lg"
                                ></FontAwesomeIcon>
                              </span>
                              {point}
                            </List.Item>
                          ))}
                        </>
                      ) : (
                        <div className="d-flex justify-content-center my-3 text-muted">
                          <Trans
                            i18nKey="noSuggestions"
                            defaults="No Suggestions"
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
SummaryFormField.propTypes = {
  summaryData: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default SummaryFormField;