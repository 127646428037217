import React, {
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Placeholder,
  Progress,
  Whisper,
  Popover,
  Notification,
  Tag,
  Tooltip,
} from "rsuite";
import {
  faUser,
  faUserCircle,
  faMapMarker,
  faMobile,
  faEnvelope,
  faFile,
  faAddressCard,
  faFileText,
  faSuitcase,
  faCheck,
  faXmark,
  faTriangleExclamation,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../app/context/UserContext";
const BasicDetailsEdit = React.lazy(() => import("./BasicDetailsEdit"));
import "./MyAccount.css";
import { useLocation } from "react-router-dom";
const CreateUserResume = React.lazy(() => import("./CreateUserResume"));
import { Trans, useTranslation } from "react-i18next";
import { Nav, Tab, Row, Col } from "react-bootstrap";
const ProfileBasicDetails = React.lazy(() =>
  import("./tabs/ProfileBasicDetails")
);
const Resume = React.lazy(() => import("./tabs/Resume"));
import Loading from "../../Loder";
const ProfileRecommendedJobs = React.lazy(() =>
  import("./tabs/ProfileRecommendedJobs")
);

import usePost from "../../customhooks/UsePost";
const BasicDetails = () => {
  const [load, setLoad] = useState(true);
  const { user, setUserDetails } = useContext(UserContext);
  const { t } = useTranslation();
  const pageUserData = useRef({});
  const location = useLocation();
  const [basicDetailsEdit, setBasicDetailsEdit] = useState(false);
  let city = user?.locations?.city;
  let state = user?.locations?.state;
  let country = user?.locations?.country;
  let loc = "";
  if (city && state && country) {
    loc = city + ", " + state + ", " + country;
  } else if (state && country) {
    loc = state + ", " + country;
  } else if (city && country) {
    loc = city + ", " + country;
  } else {
    loc = "";
  }

  useEffect(() => {
    setLoad(false);
  }, [user]);

  const [resumeID, setResumeID] = useState("");
  const {
    post: usegetpercentage,
    data,
    isLoading: getpercentageLoadin,
    error,
  } = usePost("/user/ProfileGapCalculator");
  useEffect(() => {
    if (user && user?.resumeID) {
      setResumeID(user?.resumeID);
    }
    pageUserData.current = {
      fullName: user?.fullName,
      email: user?.email,
      locations: loc,
      locationObj:
        user?.locations && user?.locations.length > 0
          ? {
              city: user?.locations[0]?.city,
              country: user?.locations[0]?.country,
              pin: {
                lat: user?.locations[0]?.pin?.lat,
                lon: user?.locations[0]?.pin?.lon,
              },
              state: user?.locations[0]?.state,
              zipcode: user?.locations[0]?.postalCode,
            }
          : {
              city: "",
              country: "",
              pin: {
                lat: "",
                lon: "",
              },
              state: "",
              zipcode: "",
            },
    };
    if (user?.phone?.phone) {
      pageUserData.current["phone"] =
        user?.phone?.internationalPhoneCode + user?.phone?.phone;
    } else {
      pageUserData.current["phone"] = "";
    }
  }, [user]);

  const edit = () => {
    setBasicDetailsEdit(true);
  };
  const backbtn = useCallback(
    () => setBasicDetailsEdit(false),
    [basicDetailsEdit]
  );
  useEffect(() => {
    if (user?.resumeID && user?.resumeID !== "") {
      setResumeID(user?.resumeID);
    }
  }, [user]);
  const [key, setKey] = useState("profileDetails");
  const [resume, setResume] = useState({});
  const [percentagedata, setpercentagedata] = useState({});
  //console.log(resume ,'resumeresumeresumeresume');
  const triggerRef = React.useRef();
  const open = () => triggerRef.current.open();
  const close = () => triggerRef.current.close();
  useEffect(() => {
    if (resume != {} && resume?.resume) {
      usegetpercentage(resume)
        .then((res) => {
          if (res?.status) {
            //console.log(res?.result?.data, "usegetpercentage");
            setpercentagedata(res?.result?.data);
            //setCustom(res?.data);
          }
        })
        .catch((error) => {
          console.error("Error while fetching custom layout:", error);
        });
    }
  }, [resume]);
  // console.log(resume, "asdefretresume");
  // const scrollRef = useRef(null);
  const [scrollRef, setscrollRef] = useState(null);
  const scrollToSection = (sectionId) => {
    setKey("profileDetails");
    close();
    setscrollRef(sectionId);

    // if (ref.current) {
    //   // ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //   ref.current.scrollIntoView({ behavior: "smooth" });
    // }
  };

  return (
    <>
      {!basicDetailsEdit ? (
        <div className={`card py-2 ${basicDetailsEdit ? "d-none" : ""}`}>
          <div className="card-header">
            <div className="row">
              <div className="col-6">
                <FontAwesomeIcon icon={faUser} className="userIcon mx-2" />
                <span className="mt-3 basic-details">
                  <Trans i18nKey="basicDetails" default="Basic Details" />
                </span>
              </div>
              <div className="col-6">
                {/* <div className=""> */}
                {percentagedata?.profileTotal < 100 && (
                  <>
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="text-warning me-2"
                    />
                    <span>
                      <b> Update your profile </b>for personalized job
                      recommendations!
                    </span>
                  </>
                )}
                {/* </div> */}
                <button
                  className="btn btn-sm btn-primary float-end shadow"
                  onClick={edit}
                >
                  <Trans i18nKey="edit" defaults="Edit" />
                </button>
              </div>
            </div>
          </div>

          {load ? (
            <div className="card">
              <div className="card-body">
                <Placeholder.Grid rows={4} columns={2} active />
              </div>
            </div>
          ) : (
            <div className="row mt-2">
              <div className="col-3 d-flex align-items-center justify-content-center">
                {user?.profileImageURL ? (
                  <>
                    <div className=" d-flex align-items-center justify-content-center">
                      <img
                        src={user?.profileImageURL}
                        alt=""
                        className="profileimage"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="userCss d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faUser} className="w-100 h-100" />
                    </div>
                  </>
                )}
              </div>
              <div className="col-9">
                <div className="row ">
                  <div className="">
                    <b>
                      {user?.fullName
                        ? user?.fullName
                        : t("notAvailable", { default: "Not Available" })}
                    </b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="mt-1">
                      <span>
                        <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                      </span>
                      {user?.email
                        ? user?.email
                        : t("notAvailable", { default: "Not Available" })}
                    </div>
                    <div className="mt-1">
                      <span>
                        <FontAwesomeIcon icon={faMobile} className="me-2" />
                      </span>
                      {user?.phone?.internationalPhoneCode && user?.phone?.phone
                        ? `${user?.phone?.internationalPhoneCode}-${user?.phone?.phone}`
                        : t("notAvailable", { default: "Not Available" })}
                    </div>
                    {/* <div className="mt-1">
                      <span>
                        <FontAwesomeIcon icon={faMapMarker} className="me-2" />
                      </span>
                      {loc?.length > 0
                        ? loc
                        : t("notAvailable", { default: "Not Available" })}
                    </div> */}
                  </div>
                  <div className="col-6  ">
                    {/* {getpercentageLoadin ? (
                      <Loading rows={2} />
                    ) : ( */}
                      <>
                        <Whisper
                          ref={triggerRef}
                          placement="auto"
                          trigger="click"
                          speaker={
                            <Popover>
                              <div className="p-1">
                                {parseInt(
                                  percentagedata?.profileScore?.experience
                                ) > 0 ? (
                                  <div className="row py-2">
                                    <div className="col-12 d-flex align-items-center">
                                      <span className="displayIcon displayIcon-sm text-success pe-2">
                                        <FontAwesomeIcon
                                          icon={faCircleCheck}
                                        ></FontAwesomeIcon>
                                      </span>
                                      <b>
                                        <Trans
                                          defaults="Experience"
                                          i18nKey="experience"
                                        />
                                      </b>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row py-2">
                                    <Whisper
                                      placement="top"
                                      controlId="control-id-hover"
                                      trigger="hover"
                                      speaker={
                                        <Tooltip className="text-center">
                                          Click here to complete the Experience
                                          section
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className="col-12 d-flex align-items-center  cursorpointer  "
                                        onClick={() =>
                                          scrollToSection("experience")
                                        }
                                      >
                                        <span className="displayIcon displayIcon-sm  text-warning pe-2">
                                          <FontAwesomeIcon
                                            icon={faTriangleExclamation}
                                          ></FontAwesomeIcon>
                                        </span>
                                        <b>
                                          <Trans
                                            defaults="Experience"
                                            i18nKey="experience"
                                          />
                                        </b>
                                      </div>
                                    </Whisper>
                                  </div>
                                )}
                                {parseInt(
                                  percentagedata?.profileScore?.education
                                ) > 0 ? (
                                  <div className="row py-2">
                                    <div className="col-12 d-flex align-items-center ">
                                      <span className="displayIcon displayIcon-sm text-success pe-2">
                                        <FontAwesomeIcon
                                          icon={faCircleCheck}
                                        ></FontAwesomeIcon>
                                      </span>
                                      <b>
                                        <Trans
                                          defaults="Education"
                                          i18nKey="education"
                                        />
                                      </b>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row py-2">
                                    <Whisper
                                      placement="top"
                                      controlId="control-id-hover"
                                      trigger="hover"
                                      speaker={
                                        <Tooltip className="text-center">
                                          Click here to complete the Education
                                          section
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className="col-12 d-flex align-items-center  cursorpointer"
                                        onClick={() =>
                                          scrollToSection("education")
                                        }
                                      >
                                        <span className="displayIcon displayIcon-sm text-warning pe-2">
                                          <FontAwesomeIcon
                                            icon={faTriangleExclamation}
                                          ></FontAwesomeIcon>
                                        </span>
                                        <b>
                                          <Trans
                                            defaults="Education"
                                            i18nKey="education"
                                          />
                                        </b>
                                      </div>
                                    </Whisper>
                                  </div>
                                )}

                                {parseInt(
                                  percentagedata?.profileScore?.skills
                                ) > 0 ? (
                                  <div className="row py-2">
                                    <div className="col-12 d-flex align-items-center">
                                      <span className="displayIcon displayIcon-sm text-success pe-2">
                                        <FontAwesomeIcon
                                          icon={faCircleCheck}
                                        ></FontAwesomeIcon>
                                      </span>
                                      <b>
                                        <Trans
                                          defaults="Skills"
                                          i18nKey="skills"
                                        />
                                      </b>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row py-2">
                                    <Whisper
                                      placement="top"
                                      controlId="control-id-hover"
                                      trigger="hover"
                                      speaker={
                                        <Tooltip className="text-nowrap">
                                          Click here to complete the skills
                                          section
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className="col-12 d-flex align-items-center  cursorpointer  "
                                        onClick={() =>
                                          scrollToSection("skills")
                                        }
                                      >
                                        <span className="displayIcon displayIcon-sm pe-2 text-warning">
                                          <FontAwesomeIcon
                                            icon={faTriangleExclamation}
                                          ></FontAwesomeIcon>
                                        </span>
                                        <b>
                                          <Trans
                                            defaults="Skills"
                                            i18nKey="skills"
                                          />
                                        </b>
                                      </div>
                                    </Whisper>
                                  </div>
                                )}
                                {parseInt(percentagedata?.profileScore?.about) >
                                0 ? (
                                  <div className="row py-2">
                                    <div className="col-12 d-flex align-items-center">
                                      <span className="displayIcon displayIcon-sm text-success pe-2">
                                        <FontAwesomeIcon
                                          icon={faCircleCheck}
                                        ></FontAwesomeIcon>
                                      </span>
                                      <b>
                                        <Trans
                                          defaults="Summary"
                                          i18nKey="summary"
                                        />
                                      </b>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row py-2">
                                    <Whisper
                                      placement="top"
                                      controlId="control-id-hover"
                                      trigger="hover"
                                      speaker={
                                        <Tooltip className="text-center">
                                          Click here to complete the Summary
                                          section
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className="col-12 d-flex align-items-center  cursorpointer"
                                        onClick={() => scrollToSection("about")}
                                      >
                                        <span className="displayIcon displayIcon-sm  pe-2 text-warning">
                                          <FontAwesomeIcon
                                            icon={faTriangleExclamation}
                                          ></FontAwesomeIcon>
                                        </span>
                                        <b>
                                          <Trans
                                            defaults="Summary"
                                            i18nKey="summary"
                                          />
                                        </b>
                                      </div>
                                    </Whisper>
                                  </div>
                                )}
                              </div>
                            </Popover>
                          }
                        >
                          <div className="w-25" onMouseOver={open}>
                            <Progress.Circle
                              gapDegree={75}
                              gapPosition="bottom"
                              strokeWidth={9}
                              trailWidth={9}
                              percent={parseInt(
                                percentagedata?.profileTotal ?? 0
                              )}
                              strokeColor={
                                percentagedata?.profileTotal < 30
                                  ? "#dc3545"
                                  : percentagedata?.profileTotal < 100
                                  ? "#ffc107"
                                  : "var(--bs-primary)"
                              }
                              // status={percentagedata?.profileTotal < 100 ? "warning" :"success"}
                            />
                          </div>
                        </Whisper>
                      </>
                    {/* )} */}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <React.Suspense>
          <BasicDetailsEdit
            pageUserData={pageUserData.current}
            toggle={basicDetailsEdit}
            back={backbtn}
          />
        </React.Suspense>
      )}
      {resumeID && resumeID != "" ? (
        <>
          {/* <div className="card">
            <div className="row">
              <div className="col-4">
                <div className="nav-item ">
                  <NavLink
                    to="/myaccount/basicdetails/profilebasicdetails"
                    className={`text-muted text-decoration-none tablink nav-link p-2 ps-3 ${
                      location.pathname === "/myaccount/basicdetails/profilebasicdetails"
                        ? "active"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faAddressCard}
                      className="iconUser me-2"
                    />
                    <span className="mt-3">Profile Details</span>
                  </NavLink>
                </div>
              </div>
              <div className="col-4">
                <div className="nav-item ">

                  <NavLink
                    to="/myaccount/basicdetails/resume"
                    className={`text-muted text-decoration-none tablink nav-link p-2 ps-3 
                    ${
                      location.pathname.includes(
                        "/myaccount/basicdetails/resume"
                      )
                        ? "active"
                        : ""
                    }`
                  }
                  >
                    <FontAwesomeIcon icon={faFile} className="iconUser me-2" />
                    <span className="mt-3">Resume</span>
                  </NavLink>
                </div>
              </div>
              <div className="col-4">
                <div className="nav-item ">
                 
                  <NavLink
                    to="/myaccount/basicdetails/profilerecommendedjobs"
                    className={`text-muted text-decoration-none tablink nav-link p-2 ps-3 ${
                      location.pathname.includes(
                        "/myaccount/basicdetails/profilerecommendedjobs"
                      )
                        ? "active"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faSuitcase}
                      className="iconUser me-2"
                    />
                    <span className="mt-3">Recommended Jobs</span>
                  </NavLink> 
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <Outlet />
          </div> */}
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="profileDetails"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                }}
              >
                <div className="card border bg-white">
                  <Row>
                    <Col sm={12}>
                      <Nav className="profileNav row">
                        <div className="col-4">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="profileDetails"
                              className=" Navtabsactive text-muted d-flex align-items-center text-decoration-none justify-content-center"
                            >
                              <span className="displayIcon displayIcon-sm me-2">
                                <FontAwesomeIcon icon={faAddressCard} />
                              </span>
                              <div
                                className="text-truncate navbarProfileLableCss"
                                title={t("profileDetails", {
                                  defaultValue: "Profile Details",
                                })}
                              >
                                <b>
                                  <Trans
                                    i18nKey="profileDetails"
                                    defaults="Profile Details"
                                  />
                                </b>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                        <div className="col-4">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="resume"
                              className=" Navtabsactive text-muted d-flex align-items-center text-decoration-none justify-content-center"
                            >
                              <span className="displayIcon displayIcon-sm me-2">
                                <FontAwesomeIcon icon={faFileText} />
                              </span>
                              <div
                                className="text-truncate navbarProfileLableCss"
                                title={t("resume", {
                                  defaultValue: "Resume",
                                })}
                              >
                                <b>
                                  <Trans
                                    i18nKey="resume"
                                    defaults="Resume"
                                  ></Trans>
                                </b>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                        <div className="col-4">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="recomendedJobs"
                              className=" Navtabsactive text-muted d-flex align-items-center text-decoration-none justify-content-center"
                            >
                              <span className="displayIcon displayIcon-sm me-2">
                                <FontAwesomeIcon icon={faSuitcase} />
                              </span>
                              <div
                                className="text-truncate navbarProfileLableCss"
                                title={t("recommendedJobs", {
                                  defaultValue: "Recommended Jobs",
                                })}
                              >
                                <b>
                                  <Trans
                                    i18nKey="recommendedJobs"
                                    defaults="Recommended Jobs"
                                  ></Trans>
                                </b>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="profileDetails">
                          {key == "profileDetails" && (
                            <React.Suspense fallback={<Loading />}>
                              <ProfileBasicDetails
                                key={"profileDetails"}
                                setResume={setResume}
                                resume={resume}
                                scrollRef={scrollRef}
                                setscrollRef={setscrollRef}
                              />
                            </React.Suspense>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="resume">
                          {key == "resume" && (
                            <React.Suspense fallback={<Loading />}>
                              <Resume key={"resume"} />
                            </React.Suspense>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="recomendedJobs">
                          {key == "recomendedJobs" && (
                            <React.Suspense fallback={<Loading />}>
                              <ProfileRecommendedJobs key={"recomendedJobs"} />
                            </React.Suspense>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </div>
              </Tab.Container>
            </div>
          </div>
        </>
      ) : (
        <>
          <CreateUserResume setResumeID={setResumeID} />
        </>
      )}
      {/* {basicDetailsEdit && (
        <React.Suspense>
          <BasicDetailsEdit
            pageUserData={pageUserData.current}
            toggle={basicDetailsEdit}
            back={backbtn}
          />
        </React.Suspense>
      )} */}
    </>
  );
};

export default BasicDetails;
