import React, {
  useState,
  useEffect,
  Suspense,
} from "react";
import profileLayout from "../../../../profiletabs.json";
import Loading from "../../../Loder";
import ProfileDetailsFields from "./fields/ProfileDetailsFields";

const ProfileDetails = ({ customTabs,resumeID,resume,setUpdateResumeDataData,resumeDetailsIsLoading,scrollRef,setscrollRef }) => {
  const [profileLayoutData, setProfileLayoutData] = useState();
  const [id, setId] = useState(resumeID ? resumeID : "");

  useEffect(() => {
    if (resumeID) {
      setId(resumeID);
    }
  }, [resumeID]);

  useEffect(() => {
    if (customTabs) {
      setProfileLayoutData(customTabs?.layout);
    } else {
      setProfileLayoutData(profileLayout);
    }
  }, [customTabs]);
  return (
    <>
      {resumeDetailsIsLoading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading rows={0} />}>
            <ProfileDetailsFields
              profileLayoutData={profileLayoutData}
              resumeId={id}
              resumeData={resume}
              setUpdateResumeDataData={setUpdateResumeDataData}
              scrollRef={scrollRef}
              setscrollRef={setscrollRef}
            />
          </Suspense>
      )}
    </>
  );
};

export default ProfileDetails;
