import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Controller, useFieldArray } from "react-hook-form";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { Form } from "react-bootstrap";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { IconButton } from "rsuite";

const PreferredLocationsFormField = ({
  preferredLocationsData,
  control,
  register,
  getValues,
  setValue,
  errors,
  fieldList,
  type

}) => {
  const {
    fields: preferredLocationFields,
    append: preferredLocationAppend,
    prepend,
    remove: preferredLocationRemoved,
    swap,
    move,
    insert,
    replace: preferredLocationReplace,
  } = useFieldArray({
    control,
    name: "preferred",
  });
  const inputRef = useRef([]);
  useEffect(() => {
    if (preferredLocationsData) {
      if (preferredLocationsData.length > 0) {
        preferredLocationReplace();
        preferredLocationAppend(
          preferredLocationsData.map((x) => {
            // console.log(x, "efqedqwdcas")
            return {
              city: x?.text,
            };
          })
        );
      } else {
        if (preferredLocationFields?.length == 0) {
          preferredLocationReplace([]);
          preferredLocationAppend([
            {
              city: "",
            },
          ]);
        }
      }
      // console.log(preferredLocationsData, "educationList");
    }
    preferredLocationsData.map((item, index) => {
      // console.log(item, "index12312321")
      setValue(`preferred[${index}].city`, item?.city);
      setValue(`preferred[${index}].locationObj`, item);
    });
  }, [preferredLocationsData]);
  const handleAddressChange = (index) => {
    // console.log(inputRef.current[index].getPlaces(), "inputRef.current[index].getPlaces()")
    // const address = inputRef.current[index].getPlaces()[0].formatted_address;
    // setValue(`preferred[${index}].city`, address)
    let place = inputRef.current[index].getPlaces()[0];
    let location = {
      text: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      address: "",
    };
    if (place) {
      location.text = place.formatted_address;
      if (place.address_components.length > 0) {
        for (let i in place.address_components) {
          if (
            place.address_components[i].types.indexOf("street_number") != -1
          ) {
            location.address += place.address_components[i].long_name + " ";
          }
          if (place.address_components[i].types.indexOf("route") != -1) {
            location.address += place.address_components[i].long_name;
          }
          if (place.address_components[i].types.indexOf("postal_code") != -1) {
            location.postalCode += place.address_components[i].long_name + " ";
          }
          if (place.address_components[i].types.indexOf("locality") != -1) {
            location.city += place.address_components[i].long_name;
          }
          if (
            place.address_components[i].types.indexOf(
              "administrative_area_level_1"
            ) != -1
          ) {
            location.state = place.address_components[i].long_name;
          }
          if (place.address_components[i].types.indexOf("country") != -1) {
            location.country = place.address_components[i].long_name;
          }
        }
      }
      setValue(`preferred[${index}].city`, place.formatted_address);
      setValue(`preferred[${index}].locationObj`, location);
    }
  };

  return (
    <>
      {preferredLocationFields.map((item, index) => {
        return (
          <div className="row my-2 d-flex " key={item?.id}>
            <div className="col-10">
              <div className="form-group">
                {index == 0 && (
                  <>
                  <label className="text-dark">
                  {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
                  </label>
                  {fieldList?.required && (
                    <span className="text-danger">{"*"}</span>
                  )}
                  </>
                )}

                <Controller
                  control={control}
                  name={`preferred[${index}].city`}
                  refs={register(`preferred[${index}].city`, {
                    required: fieldList?.required ? true : false,
                  })}
                  render={({ field: { onChange, onBlur, value, refs } }) => (
                    <div className="w-100">
                      <StandaloneSearchBox
                        onLoad={(ref) => {
                          // console.log(ref, "jfnjebndjbnew")
                          inputRef.current[index] = ref;
                        }}
                        onPlacesChanged={() => handleAddressChange(index)}
                      >
                        <Form.Control
                          size="sm"
                          placeholder={
                            t("enter", { defaultValue: "Enter" }) +
                            " " +
                            t("preferredLocation", {
                              defaultValue: "Preferred Location",
                            })
                          }
                          onChange={onChange}
                          disabled={
                            type=="customLayout" || fieldList?.editable || !fieldList ? false : true
                          }
                          // className='w-100'
                          value={value}
                          block="true"
                        />
                      </StandaloneSearchBox>
                    </div>
                  )}
                />
                {errors?.["preferred"]?.[index]?.city?.type == "required" && (
                  <span className="text-danger">
                    {errors?.["preferred"]?.[index]?.city?.message != "" ? (
                      errors?.["preferred"]?.[index]?.city?.message
                    ) : (
                      <Trans
                        i18nKey="preferredLocationIsRequired"
                        defaults="Preferred Location is required"
                      />
                    )}{" "}
                  </span>
                )}
              </div>
            </div>
            <div className="col-2 align-items-end d-flex">
              <span
                className="pb-1 IconArea actionIcon actionIcon-sm"
                onClick={() => {
                  preferredLocationRemoved(index);
                }}
              >
                <IconButton
                  icon={
                    <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                  }
                  size="sm"
                />
                {/* <FontAwesomeIcon icon={faMinusCircle} ></FontAwesomeIcon> */}
              </span>
            </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col-10 ">
          <div className="d-flex justify-content-center">
            <span
              className="pb-1 IconArea actionIcon actionIcon-sm"
              onClick={() => {
                preferredLocationAppend({ city: "" });
              }}
            >
              <IconButton
                icon={<FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>}
                size="sm"
              />
            </span>
          </div>
        </div>
      </div>

      {/* <div className='row my-3'>
                <div className='col-sm-12 col-md-12'>
                    <div className='d-flex justify-content-center'>
                        
                    </div>
                    <div className='d-flex justify-content-center'>
                        <span> Add Location</span>
                    </div>

                </div>
            </div> */}
    </>
  );
};

export default PreferredLocationsFormField;
