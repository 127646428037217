// import Image from "react-bootstrap/Image";
// import Navbar from "react-bootstrap/Navbar";

import { Image,Navbar } from "react-bootstrap";
import { Trans } from "react-i18next";
function NavFooter() {
  return (
    <div className="main-footer w-100">
      {/* <Container fluid> */}
      <div className="row w-100 d-flex pt-4">
        <div className="col-1"></div>
        <div className="col-4 ms-3">
          <Image
            src="https://recruiterqa.maprecruit.com/images/coarthatechnosolutionsIN/logos/552d016291bad07ccbb455fe_logo_MapRecruitLogo-N-3.png"
            // className="w-100"
            style={{ maxHeight: "25px" }}
          />
          <div className="text-primary py-2">
            <p>
              {/* Powering Frictionless Recruiting */}
              <Trans  i18nKey="poweringFrictionlessRecruiting" defaults="Powering Frictionless Recruiting" /> 
            </p>
          </div>
          <div className="text-primary">
            <i className="fa-brands fa-linkedin"></i>
            {/* <FontAwesomeIcon icon={faLinkedin}  /> */}
          </div>
        </div>
        <div className="col-4"></div>
      </div>
      {/* </Container> */}
    </div>
  );
}

export default NavFooter;
