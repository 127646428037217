
import { useEffect, useState } from "react";
import { Container, Navbar, Image } from "react-bootstrap";
import axios from "axios";
import useIsSsr from "../../customhooks/UseIsSSR";
import { useSearchParams } from "react-router-dom";


function CareersHeader() {
  const isSsr = useIsSsr();
  const [searchParams] = useSearchParams();
  const [logo, setLogo] = useState("");
  const [interviewID, setInterviewID] = useState(null);
  const [campaignID, setCampaignID] = useState(null);
  const [companyID, setCompanyID] = useState(null);
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    if (searchParams.get("iid") !== null) {
      setInterviewID(searchParams.get("iid"))
    }
    if (searchParams.get("cid") !== null) {
      setCampaignID(searchParams.get("cid"))
    }
    if (searchParams.get("companyid") !== null) {
      setCompanyID(searchParams.get("companyid"))
    }
  }, [searchParams]);

  useEffect(() => {
    if (interviewID && companyID) {
      setPayload({
        interviewID: interviewID,
        companyID: companyID
      })
    } else if (campaignID) {
      setPayload({
        campaignID: campaignID
      })
    }
  }, [interviewID, companyID, campaignID])

  const getLogo = async () => {
    let data = await axios.get(
      window.location.origin +
      "/jsv4/webapi/getCompanyLogo",
      {
        params: {
          interviewID: interviewID !== null ? interviewID : "",
          campaignID: campaignID !== null ? campaignID : "",
          companyID: companyID !== null ? companyID : ""
        }
      });
    // console.log("hghshshfsgfshgfhsgf", data?.data);

    if (data?.data?.status) {
      setLogo(data?.data?.clientLogo)
    }
  }

  useEffect(() => {
    if (payload !== null) {
      getLogo();
    }
  }, [payload])

  return (
    <>
      <Navbar expand="lg" className="main-header px-0" sticky="top">
        <Container fluid>
          <div className=" w-100 d-flex justify-content-between align-items-center">
            <div>
              {
                logo?.length > 0 && (
                  <Image
                    src={logo}
                    // src="https://recruiterqa.maprecruit.com/images/coarthatechnosolutionsIN/logos/552d016291bad07ccbb455fe_logo_MR_light.png"
                    className="w-100"
                    style={{ maxHeight: "30px" }}
                  />
                )
              }
            </div>
          </div>
        </Container>
      </Navbar>
      {/* <Divider className="mt-2" /> */}
    </>
  );
}

export default CareersHeader;