import React, { useState, useEffect } from "react";
import { Modal, SelectPicker, useToaster, Notification, Radio, RadioGroup } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../customhooks/UsePost";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../../utilities/telephone.css";
import countryNames from "react-phone-number-input/locale/en";

export default function SlotBookingModal({
    selectedSlot,
    setSelectedSlot,
    timeFormat,
    campaignID,
    interviewID,
    currentRound,
    collectionName,
    companyID,
    bookedSlot,
    setSlotID,
    resumeID,
    collections,
    clientID,
    setLastSlotBooked
}) {
    const toaster = useToaster();
    const { t } = useTranslation()
    const [open, setOpen] = useState(true);
    const {
        register,
        handleSubmit,
        control,
        watch,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
            meetType: null
        }
    });
    let [searchParams] = useSearchParams();
    let slotTimeFormat = "HH:mm"
    if (timeFormat && timeFormat != "") {
        slotTimeFormat = timeFormat
    }
    const [meetTypeData, setMeetTypeData] = useState([]);
    const [sendbackResult, setSendbackResult] = useState(null);
    useEffect(() => {
        if (currentRound?.availableMeetTypes && currentRound?.availableMeetTypes?.length > 0) {
            let meetTypeList = currentRound?.availableMeetTypes?.map((item) => {
                return { label: item, value: item }
            })
            setMeetTypeData(meetTypeList)
        }
    }, [currentRound]);

    // console.log("sdhagsyegdywq", selectedSlot);

    const [showUnsubscribe, setShowUnsubscribe] = useState(false)
    const [optoutEmails, setOptoutEmails] = useState([])
    const [optoutPhones, setOptoutPhones] = useState([])

    // usePost
    const {
        post: bookSlotForJobseeker,
        data: bookSlotForJobseekerData,
        isLoading: bookSlotForJobseekerLoading,
        error: bookSlotForJobseekerError,
    } = usePost("/schedulevideo/bookSlotForJobseeker");

    // usePost
    const {
        post: rescheduleTimeSlot,
        data: rescheduleTimeSlotData,
        isLoading: rescheduleTimeSlotLoading,
        error: rescheduleTimeSlotError,
    } = usePost("/schedulevideo/rescheduleTimeSlot")

    // usePost
    const {
        post: checkJobseekerSubscribeStatus,
        data: checkJobseekerSubscribeStatusData,
        isLoading: checkJobseekerSubscribeStatusLoading,
        error: rcheckJobseekerSubscribeStatusError,
    } = usePost("/schedulevideo/checkJobseekerSubscribeStatus")

    const [checkSubscribe, setCheckSubscribe] = useState(true)
    const onSubmit = async (data) => {
        let flag = true;
        let meetType = "";
        if (currentRound?.roundType == "Interview") {
            if (data?.meetType === null || data.meetType === undefined) {
                flag = false
            }
        }

        if (flag) {
            if (currentRound?.roundType == "Interview") {
                meetType = data?.meetType
            } else {
                meetType = currentRound?.roundType
            }

            let isSubscribed = true
            if (checkSubscribe) {
                let subscribeStatusCheck = {
                    interviewID: interviewID ?? "",
                    roundNumber: currentRound?.roundNumber ?? "",
                    companyID: companyID ?? "",
                    campaignID: campaignID ?? ""
                }

                let response = await checkJobseekerSubscribeStatus(subscribeStatusCheck)
                if (response?.status && response?.result?.status) {
                    if (response?.result?.emailUnsubscribed || response?.result?.phoneUnsubscribed) {
                        isSubscribed = false
                        setShowUnsubscribe(true)
                        setValue("subscribeType", "resubscribe")
                        // if (response?.result?.emailReachoutEnabled) {
                        if (response?.result?.optOutEmails && response?.result?.optOutEmails?.length > 0) {
                            setOptoutEmails(response?.result?.optOutEmails)
                        }
                        // }
                        // if (response?.result?.smsReachoutEnabled) {
                        if (response?.result?.optOutPhones && response?.result?.optOutPhones?.length > 0) {
                            setOptoutPhones(response?.result?.optOutPhones)
                        }
                        // }
                    }
                } else {
                    console.log("checkJobseekerSubscribeStatus ", response);
                }
            }

            if (isSubscribed) {
                if (window.location.pathname.includes("confirmschedule")) {
                    let rescheduleObj = {
                        slotID: selectedSlot?._id,
                        interviewID: interviewID,
                        campaignID: campaignID,
                        roundNumber: currentRound?.roundNumber,
                        collectionName: collectionName,
                        userID: selectedSlot?.userID,
                        participant: searchParams.get("participant") != null ? searchParams.get("participant") : "",
                        companyID: companyID,
                        meetType: meetType
                    }

                    // console.log("rescheduleObj", rescheduleObj)

                    rescheduleTimeSlot(rescheduleObj)
                        .then(async (resp) => {
                            // console.log("response", resp)

                            if (resp?.status) {
                                if (resp?.result?.status) {
                                    try {
                                        let data = await axios.get(
                                            window.location.origin +
                                            "/jsv4/webapi/schedulevideo/checkInterviewBookingStatus",
                                            {
                                                params: {
                                                    interviewID: interviewID,
                                                    roundNumber: currentRound?.roundNumber,
                                                    campaignID: campaignID,
                                                    companyID: companyID,
                                                },
                                            }
                                        );
                                        // console.log("gsfdhgfsgsddddddddddd", data?.data)

                                        if (data?.data?.status) {
                                            setSendbackResult(data?.data)
                                            setOpen(false)
                                        }
                                    } catch (error) {
                                        console.log("Error", error)
                                    }
                                } else {
                                    setSendbackResult(resp)
                                    if (resp?.result?.message && resp?.result?.message != "" && resp?.result?.message.includes("Slot not available")) {
                                        toaster.push(
                                            <Notification type={"info"} header={t("info", { defaultValue: "info" })} closable>
                                                {t("slotNotAvailable", { defaultValue: "Slot not available" })}
                                            </Notification>,
                                            { placement: "topEnd" }
                                        );
                                        setOpen(false)
                                    } else if (resp?.result?.message && resp?.result?.message != "" && resp?.result?.message.includes("Need to schedule first before rescheduling")) {
                                        toaster.push(
                                            <Notification type={"info"} header={t("info", { defaultValue: "info" })} closable>
                                                {t("needToScheduleFirstTheSlotBeforeRescheduling", { defaultValue: "Need to schedule first before rescheduling" })}
                                            </Notification>,
                                            { placement: "topEnd" }
                                        );
                                        setOpen(false)
                                    } else {
                                        toaster.push(
                                            <Notification type={"error"} header={t("error", { defaultValue: "error" })} closable>
                                                {t("somethingWentWrongPleaseTryAgainLater", { defaultValue: "Something went wrong please try again later" })}
                                            </Notification>,
                                            { placement: "topEnd" }
                                        );
                                        setOpen(false)
                                    }
                                }
                            }

                        }).catch((error) => {
                            console.log("Error", error)
                        })

                } else {
                    if (window.location.pathname.includes("schedulevideo")) {
                        let apiObj = {
                            campaignID: campaignID,
                            interviewID: interviewID,
                            collectionName: collectionName,
                            slotID: selectedSlot?._id,
                            userID: selectedSlot?.userID,
                            participants: selectedSlot?.participants,
                            qualifyNumber: currentRound?.roundNumber,
                            companyID: companyID,
                            sourceOfBooking: "Slot Booking UI",
                            roundType: currentRound?.roundType,
                            meetType: meetType,
                            from: window.location.search.includes("cid") ? "autoscheduleUrl" : ""
                        }
                        // console.log("apiObj", apiObj)

                        bookSlotForJobseeker(apiObj)
                            .then(async (resp) => {
                                // console.log("response", resp)
                                if (resp?.status) {
                                    if (resp?.result?.status) {
                                        setLastSlotBooked(true);
                                        try {
                                            let cookieSetting = await axios.post(window.location.origin + "/jsv4/webapi/setcookie", {
                                                cookieName: `autoScheduleSlotDetails_${campaignID}_${currentRound?.roundNumber}`,
                                                cookieValue: JSON.stringify({
                                                    interviewID: interviewID,
                                                    companyID: companyID
                                                })
                                            })

                                            // console.log("cookieSetting : ", cookieSetting?.data)
                                        } catch (error) {
                                            console.log("Error in setcookie", error);
                                        }

                                        try {
                                            let data = await axios.get(
                                                window.location.origin +
                                                "/jsv4/webapi/schedulevideo/checkInterviewBookingStatus",
                                                {
                                                    params: {
                                                        interviewID: interviewID,
                                                        roundNumber: currentRound?.roundNumber,
                                                        campaignID: campaignID,
                                                        companyID: companyID,
                                                    },
                                                }
                                            );
                                            // console.log("gsfdhgfsgsddddddddddd", data?.data)

                                            if (data?.data?.status) {
                                                setSendbackResult(data?.data)
                                                setOpen(false)
                                            }
                                        } catch (error) {
                                            console.log("Error", error)
                                        }
                                    } else {
                                        setSendbackResult(resp)
                                        if (resp?.result?.message && resp?.result?.message != "" && resp?.result?.message.includes("Slot not available")) {
                                            toaster.push(
                                                <Notification type={"info"} header={t("info", { defaultValue: "info" })} closable>
                                                    {t("slotNotAvailable", { defaultValue: "Slot not available" })}
                                                </Notification>,
                                                { placement: "topEnd" }
                                            );
                                            setOpen(false)
                                        } else if (resp?.result?.message && resp?.result?.message != "" && resp?.result?.message.includes("you have already booked a slot")) {
                                            toaster.push(
                                                <Notification type={"info"} header={t("info", { defaultValue: "info" })} closable>
                                                    {t("youHaveAlreadyBookedASlot", { defaultValue: "You have already booked a slot" })}
                                                </Notification>,
                                                { placement: "topEnd" }
                                            );
                                            setOpen(false)
                                        } else {
                                            toaster.push(
                                                <Notification type={"error"} header={t("error", { defaultValue: "error" })} closable>
                                                    {t("somethingWentWrongPleaseTryAgainLater", { defaultValue: "Something went wrong please try again later" })}
                                                </Notification>,
                                                { placement: "topEnd" }
                                            );
                                            setOpen(false)
                                        }
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log("Error", error)
                            })
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (sendbackResult != null) {
            bookedSlot(sendbackResult);
        }
    }, [sendbackResult]);

    const [showContact, setShowContact] = useState(false);
    const handleSubscribeChange = (value) => {
        if (value == "providedifferentcontactdetails") {
            setShowContact(true)
        } else {
            setShowContact(false)
        }
    }

    const {
        post: resubscribeForJobseeker,
        // data: saveSubscriptionStatusData,
        isLoading: saveSubscriptionStatusLoading,
        // error: saveSubscriptionStatusError,
    } = usePost("/resume/resubscribeForJobseeker");
    const {
        post: resumeDbSchema,
    } = usePost("/user/getresumedbschema");

    const onSave = async () => {
        // console.log("getvalues", getValues());
        if (getValues()?.subscribeType == "resubscribe" || getValues()?.subscribeType == "providedifferentcontactdetails") {
            let obj = {
                resumeID: resumeID != "" ? resumeID : "",
                companyID: companyID ?? "",
                collections: collections ?? null,
                clientID: clientID ?? "",
                userID: selectedSlot?.userID ?? "",
                campaignID: campaignID ?? "",
                target: getValues()?.subscribeType != undefined ? getValues()?.subscribeType : "",
                interviewID: interviewID ?? "",
                roundNumber: currentRound?.roundNumber ?? ""
            }
            if (getValues()?.subscribeType == "resubscribe") {
                if (optoutEmails && optoutEmails?.length > 0) {
                    optoutEmails.forEach((x, index) => {
                        if (index == 0) {
                            x.subscribeStatus = "Opt-in"
                        }
                    })
                    obj.emails = optoutEmails[0]
                }
                if (optoutPhones && optoutPhones?.length > 0) {
                    optoutPhones.forEach((y, i) => {
                        if (i == 0) {
                            y.subscribeStatus = "Opt-in"
                        }
                    })
                    obj.phones = optoutPhones[0]
                }
            } else {
                let resumeSchema = await resumeDbSchema({ get: "schema" })
                if (resumeSchema?.status && resumeSchema?.result?.status) {
                    resumeSchema = resumeSchema?.result?.resumeSchema
                }
                let email;
                if (getValues()?.email && getValues()?.email != undefined && resumeSchema?.schema) {
                    email = resumeSchema?.schema?.listSchema?.emails
                    email.email = getValues()?.email?.toLowerCase()
                    email.text = getValues()?.email?.toLowerCase()
                    obj.newEmail = email
                    // obj.optOutEmails = optoutEmails
                }
                let phone;
                if (getValues()?.phone && getValues()?.phone != undefined && resumeSchema?.schema) {
                    phone = resumeSchema?.schema?.listSchema?.phones
                    let parsedNumber = parsePhoneNumber(String(getValues()?.phone));
                    phone.text = getValues()?.phone?.toString();
                    phone.country = countryNames[parsedNumber?.country];
                    phone.countryCode = parsedNumber?.country;
                    phone.internationalPhoneCode = "+" + parsedNumber?.countryCallingCode;
                    phone.phoneNumberOnly = parsedNumber?.nationalNumber;
                    obj.newPhone = phone
                    // obj.optOutPhones = optoutPhones
                }
            }

            resubscribeForJobseeker(obj).then((res) => {
                console.log("resubscribeForJobseeker", res);
                setShowUnsubscribe(false)
                if (res?.status && res?.result?.status) {
                    toaster.push(
                        <Notification type={"info"} header={t("info", { defaultValue: "info" })} closable>
                            {t("resumeDetailsUpdatedSuccessfully", { defaultValue: "Resume Details Updated successfully!" })}
                        </Notification>,
                        { placement: "topEnd" }
                    );
                }
            }).catch((err) => {
                console.log("err", err);
                setShowUnsubscribe(false)
                setCheckSubscribe(false)
            })
        } else {
            setShowUnsubscribe(false)
            setCheckSubscribe(false)
        }
    }

    return (
        <Modal open={open} onClose={() => setSelectedSlot(null)} overflow={false} size="sm">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="px-3">
                {
                    showUnsubscribe ?
                        <>
                            <div className="pb-2">
                                <Trans
                                    i18nKey="weHaveObservedThatYourEmailAddressOrMobileNumberHasBeenOptedOutInOurSystemForFurtherUpdatesRegardingThisRoleOrForFutureOpportunitiesPleaseResubscribe"
                                    defaults="We have observed that your Email address {{emails}} / Mobile number {{phones}} has been opted out in our system.For further updates regarding this role or for future opportunities, please resubscribe"
                                    values={{
                                        emails: optoutEmails?.length > 0 ? optoutEmails?.map((x) => x?.email) : [],
                                        phones: optoutPhones?.length > 0 ? optoutPhones?.map((x) => x?.internationalPhoneCode + x?.phoneNumberOnly) : []
                                    }}
                                />
                            </div>
                            <div className="resubscribe">
                                <Controller
                                    control={control}
                                    name="subscribeType"
                                    refs={register("subscribeType")}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <RadioGroup
                                            name="radioList"
                                            value={value}
                                            onChange={(value) => {
                                                onChange(value);
                                                handleSubscribeChange(value);
                                            }}
                                        >
                                            <Radio value="resubscribe">
                                                <Trans i18nKey="resubscribe" defaults="Resubscribe" />
                                            </Radio>
                                            <Radio value="idontwantto">
                                                <Trans i18nKey="iDontWantTo" defaults="I don't want to" />
                                            </Radio>
                                            <Radio value="providedifferentcontactdetails">
                                                <Trans i18nKey="provideDifferentContactDetails" defaults="Provide different contact details" />
                                            </Radio>
                                        </RadioGroup>
                                    )}
                                />
                            </div>
                            <div className={showContact ? "pt-2" : "d-none"}>
                                {optoutEmails && optoutEmails?.length > 0 && (
                                    <div className="">
                                        <Trans i18nKey="email" defaults="Email" />
                                        <span className="text-danger">{"*"}</span>
                                        <Controller
                                            control={control}
                                            name={"email"}
                                            refs={register("email", {
                                                required: getValues()?.subscribeType == "providedifferentcontactdetails" ? true : false,
                                                pattern: {
                                                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/gi,
                                                    message: t("pleaseEnterValidEmailAddress", { defaultValue: "please enter valid email address" })
                                                },
                                                validate: (v) => {
                                                    if (v) {
                                                        return v.replace(/\s/g, "") != ""
                                                    }
                                                }
                                            })}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <input
                                                    type="email"
                                                    className='form-control'
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e?.target?.value)
                                                    }}
                                                    placeholder={t("pleaseEnterEmail", { defaultValue: "Please enter email" })}
                                                />
                                            )}
                                        />
                                        {errors?.email?.type == "pattern" && (
                                            <span className="text-danger">
                                                <Trans i18nKey="pleaseEnterValidEmailAddress" defaults="Please enter valid email address" />
                                            </span>
                                        )}
                                        {(errors?.email?.type == "required" || errors?.email?.type == "validate") && (
                                            <span className="text-danger">
                                                <Trans i18nKey="emailIsRequired" defaults="Email is required" />
                                            </span>
                                        )}
                                    </div>
                                )}
                                {optoutPhones && optoutPhones?.length > 0 && (
                                    <div className="py-2">
                                        <Trans i18nKey="phoneNumber" defaults="Phone Number" />
                                        <span className="text-danger">{"*"}</span>
                                        <Controller
                                            control={control}
                                            name={"phone"}
                                            refs={register("phone")}
                                            rules={{
                                                required: getValues()?.subscribeType == "providedifferentcontactdetails" ? true : false,
                                                validate: (value) => {
                                                    if (value) {
                                                        return isValidPhoneNumber(String(value));
                                                    }
                                                },
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <PhoneInput
                                                    defaultCountry={"US"}
                                                    className="from-control border-0 p-0"
                                                    placeholder={t("pleaseEnterPhoneNumber", { defaultValue: "Please enter phone number" })}
                                                    // value={
                                                    //     getValues()?.phones[index]?.phone?.includes("+")
                                                    //         ? getValues()?.phones[index]?.phone
                                                    //         : `+1 ${getValues()?.phones[index]?.phone}`
                                                    // }
                                                    onChange={(value) => {
                                                        onChange(value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.phone?.type == "validate" && (
                                            <span className="text-danger">
                                                <Trans i18nKey="pleaseEnterValidPhoneNumber" defaults="Please enter valid phone number" />
                                            </span>
                                        )}
                                        {errors?.phone?.type == "required" && (
                                            <span className="text-danger">
                                                <Trans i18nKey="phoneNumberIsRequired" defaults="Phone number is required" />
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </>
                        :
                        <div>
                            <div className="row pb-2">
                                <div className="col-12">
                                    <Trans i18nKey="event" defaults="Event" />{" "}{":"}{" "}{currentRound?.f2fSchedule?.subject != "" ? currentRound?.f2fSchedule?.subject : <Trans i18nKey="noEvent" defaults="No event" />}
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-1 items-align-center ">
                                    <FontAwesomeIcon icon={faClock} />
                                </div>
                                <div className="col-10 px-0 text-primary">
                                    <div className="d-flex align-items-center ms-2">
                                        {DateTime.fromISO(selectedSlot?.startDate).setZone(selectedSlot?.timeZoneFullName).toFormat("DDDD")}{" "}
                                        {DateTime.fromISO(selectedSlot?.startDate).setZone(selectedSlot?.timeZoneFullName).toFormat(slotTimeFormat)}{" "}{"-"}{" "}
                                        {selectedSlot?.endDate != "" && selectedSlot?.endDate != "Invalid Datetime" && (
                                            DateTime.fromISO(selectedSlot?.endDate).setZone(selectedSlot?.timeZoneFullName).toFormat(slotTimeFormat)
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-1 items-align-center">
                                    <FontAwesomeIcon icon={faGlobe} />
                                </div>
                                <div className="col-10 px-0 ms-2">
                                    {selectedSlot?.timeZoneFullName != "" ? (
                                        selectedSlot?.timeZoneFullName
                                    ) : <Trans i18nKey="noTimezone" defaults="No timezone" />}
                                </div>
                            </div>
                            {currentRound?.roundType == "Interview" && (
                                <div className="row">
                                    <div className="col-12">
                                        <Form.Label><Trans i18nKey="meetType" defaults="Meet type" />{" "}{":"}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="meetType"
                                            refs={register("meetType")}
                                            // rules={{
                                            //     required: true
                                            // }}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <SelectPicker
                                                    data={meetTypeData}
                                                    onChange={onChange}
                                                    value={value}
                                                    className="w-50 px-2"
                                                    cleanable={false}
                                                    placeholder="Select Meet Type"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="float-start"
                    onClick={() => setSelectedSlot(null)}
                >
                    <Trans i18nKey="close" defaults="Close" />
                </Button>
                <Button variant="primary"
                    onClick={showUnsubscribe ? handleSubmit(onSave) : handleSubmit(onSubmit)}
                    disabled={
                        currentRound?.roundType == "Interview" ?
                            bookSlotForJobseekerLoading || rescheduleTimeSlotLoading || watch()?.meetType === null || checkJobseekerSubscribeStatusLoading || saveSubscriptionStatusLoading :
                            bookSlotForJobseekerLoading || rescheduleTimeSlotLoading || checkJobseekerSubscribeStatusLoading || saveSubscriptionStatusLoading
                    }
                >
                    {(bookSlotForJobseekerLoading || rescheduleTimeSlotLoading || checkJobseekerSubscribeStatusLoading || saveSubscriptionStatusLoading) &&
                        <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                            className="me-1"
                        />
                    }
                    {
                        showUnsubscribe ?
                            <Trans i18nKey="save" defaults="Save" /> :
                            <Trans i18nKey="submit" defaults="Submit" />
                    }
                </Button>
            </Modal.Footer>
        </Modal >
    )
}
