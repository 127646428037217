import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import './Login.css'

export default function LoginIcon({ iconName }) {
    return (
        <div className="d-flex justify-content-center mb-2">
            {/* <div className="userIconCss"> */}
            <img
                src="https://trcqa.maprecruit.com/images/trcstaffingUS/logos/6112806bc9147f673d28c6eb_logo_TRC_Logo_PNG_Full_Size.png"
                className="userIconCss"
            />
            {/* <img
                src="https://trcqa.maprecruit.com/images/trcstaffingUS/logos/6112806bc9147f673d28c6eb_6112806bc9147f673d28c6ed_banner_TRC_IconRGB_COLOR.png"
                className="userIconCss"
            /> */}
            {/* <FontAwesomeIcon
                    className=" w-100 h-100 d-flex justify-content-center "
                    icon={iconName}
                    size={"2x"}
                /> */}
            {/* </div> */}
        </div>
    )
}