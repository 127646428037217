import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TagInput, TagPicker } from "rsuite";
import { Trans } from "react-i18next";
import { t } from "i18next";
import campaignLanguagesList from "../../../utilities/campaignLanguagesList";

const LanguagesKnownFormField = ({
  languagesKnownData,
  control,
  getValues,
  errors,
  register,
  setValue,
  fieldList,
  type
}) => {
  useEffect(() => {
    if (languagesKnownData && languagesKnownData.length > 0) {
      setValue(
        "languagesKnown",
        languagesKnownData.map((x) => x?.text )
      );
    }
  }, [languagesKnownData]);
  return (
    <>
      <div className="form-group">
        <label className="text-dark">
        {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
        </label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
        <Controller
          control={control}
          name={"languagesKnown"}
          refs={register("languagesKnown", {
            required:fieldList?.required ? true : false,
            // validate: {
            //   emptyValue: (v) =>
            //     v && v != "" ? v.replace(/\s/g, "") != "" : true,
            // },
        })}
          render={({ field: { onChange, onBlur, value } }) => (
            <TagPicker
              data={
                campaignLanguagesList
                  ? campaignLanguagesList.map((item) => ({
                      label: item.language,
                      value: item.language.toLowerCase(),
                    }))
                  : []
              }
              size="sm"
              searchable={true}
              block
              value={value}
              disabled={type=="customLayout" ||fieldList?.editable || !fieldList ? false : true}
              placeholder={t("selectLanguage", {
                defaultValue: "Select language",
              })}
              onChange={(e) => {
                onChange(e);
              }}
              cleanable={false}
            />
            // <TagInput
            //     size="sm"
            //     className="w-100"
            //     placeholder={fieldList ? t("enter", { defaultValue: "Enter" }) + " " + t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName || "Languages Known" }) :
            //         t("enter", { defaultValue: "Enter" }) + " " + t("languagesKnown", { defaultValue: "Languages Known" })
            //     }
            //     disabled={(fieldList?.editable || !fieldList) ? false : true}
            //     value={value}
            //     onChange={onChange}
            // />
          )}
        />
        {errors?.languagesKnown?.type == "required" && (
          <span className="text-danger">
            {errors?.languagesKnown?.message != "" ? (
              errors?.languagesKnown?.message
            ) : (
              <Trans
                i18nKey="languagesKnownIsRequired"
                defaults="Languages Known is required"
              />
            )}{" "}
          </span>
        )}
      </div>
    </>
  );
};

export default LanguagesKnownFormField;
