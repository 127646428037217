import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCalendarDays,
  faBuilding,
  faLocationDot,
  faUserTie,
  faBusinessTime,
  faIdBadge,
  faBuildingUser,
} from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { Whisper, Tooltip, Tag,TagGroup } from "rsuite";
import "../../MyAccount.css";
import ShowMore from "./ShowMore";
import ExperienceSessionEdit from "../forms/ExperienceSessionEdit";
import DurationCalculation from "./DurationCalculation";
import { v4 as uuidv4 } from "uuid";
const Experience = ({
  experienceRow,
  resumeId,
  standardFieldsList,
  resumeData,
  status,
  setUpdateResumeDataData,
  scrollRef,
  setscrollRef,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleExperienceOpen = () => setOpen(true);
  const [experienceRowsList, setexperienceRows] = useState([]);

  useEffect(() => {
    setexperienceRows(experienceRow);
  }, [experienceRow]);
  useEffect(() => {
    if (scrollRef == "experience") {
      handleExperienceOpen();
    }
  }, [scrollRef]);
  return (
    <>
      <div className="card h-100">
        <div className="card-body ">
          <div className="row">
            <div className="col-10 d-flex  align-items-center">
              <h6 className="text-primary m-0">
                <Trans i18nKey="experience" defaults="Experience" />
              </h6>
            </div>
            {/* {status != "MERGED" &&
            status != "DISQUALIFIED" &&
            status != "DELETED" ? (
              <> */}
            <div className="col-2 justify-content-end d-flex">
              {/* {standardFieldsList?.editable ? (
                    <> */}
              <span
                className="IconArea actionIcon actionIcon-sm"
                onClick={handleExperienceOpen}
              >
                <FontAwesomeIcon className="mx-2" icon={faEdit} />
              </span>
              {/* </>
                  ) : null} */}
            </div>
            {/* </>
            ) : (
              <></>
            )} */}
          </div>
          <div className="row">
            <div className="col-md-12">
              {resumeData?.resume?.professionalExperience?.length > 0 ? (
                resumeData?.resume?.professionalExperience?.map(
                  (x, index) => {
                    return (
                      <div className="row " key={uuidv4()}>
                        <div className="col-md-12 ">
                          <div
                            className={
                              resumeData?.resume?.professionalExperience
                                ?.length != 1
                                ? "border-bottom"
                                : ""
                            }
                          >
                            <div className="mt-2">
                              <div className="row mb-3">
                                <div className="col-xs-12 col-md-6">
                                  <i className="fas fa-coins fa-1x  pe-2 text-primary"></i>
                                  {x?.jobTitle?.text &&
                                  x?.jobTitle?.text !== "" ? (
                                    x?.jobTitle?.text
                                  ) : (
                                    <span className="textColor2">
                                      <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                    </span>
                                  )}
                                </div>
                                <div className="col-xs-12 col-md-6">
                                  <i className="fa-solid fa-briefcase fa-1x  pe-2 text-primary"></i>
                                  {x?.startDate?.text !== "" ||
                                  x?.endDate?.text !== "" ? (
                                    <>
                                      {x?.startDate?.text &&
                                      x?.startDate?.text !== "" ? (
                                        <>{x?.startDate?.text}</>
                                      ) : (
                                        <Trans i18nKey="notSpecified" defaults="Not Specified" />
                                      )}{" "}
                                      -{" "}
                                      {x?.endDate?.text &&
                                      x?.endDate?.text !== "" ? (
                                        <>{x?.endDate?.text}</>
                                      ) : (
                                        <Trans i18nKey="notSpecified" defaults="Not Specified" />
                                      )}
                                    </>
                                  ) : <Trans
                                  i18nKey="notSpecified"
                                  defaults="Not Specified"
                                />}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-xs-12 col-md-6">
                                  <i className="fa-solid fa-building fa-1x  pe-2 text-primary"></i>
                                  {x?.company?.text &&
                                  x?.company?.text !== "" ? (
                                    x?.company?.text
                                  ) : (
                                    <span className="textColor2">
                                      <Trans
                                  i18nKey="notSpecified"
                                  defaults="Not Specified"
                                />
                                    </span>
                                  )}
                                </div>
                                <div className="col-xs-12 col-md-6">
                                  <i className="fa-solid fa-location-dot fa-1x  pe-2 text-primary"></i>
                                  {x?.location?.text &&
                                  x?.location?.text !== "" ? (
                                    x?.location?.text
                                  ) : (
                                    <span className="textColor2">
                                      <Trans
                                  i18nKey="notSpecified"
                                  defaults="Not Specified"
                                />
                                    </span>
                                  )}
                                </div>
                              </div>
                              {x?.description && x?.description !== "" ? (
                                <div className="row mb-3">
                                  <div className="col-12 white-space-line">{x?.description}</div>
                                </div>
                              ): <Trans
                              i18nKey="notSpecified"
                              defaults="Not Specified"
                            />}
                              {x?.skills && x?.skills?.length > 0 && (
                                <div className="row mb-3">
                                  <div className="col-12">
                                    <TagGroup>
                                      {x?.skills?.map((y, index) => {
                                        return (
                                          <Tag
                                            className="px-2 border border-primary bg-white rounded-pill"
                                            key={index}
                                          >
                                            {y?.text}
                                          </Tag>
                                        );
                                      })}
                                    </TagGroup>
                                  </div>
                                </div>
                              )}
                            </div>

                            {/* <div className="mt-2">
                              {experienceRowsList.map((sections, index) => {
                                let colNumber = 12;
                                if (sections?.columns.length > 0) {
                                  colNumber = 12 / sections?.columns.length;
                                }
                                return (
                                  <div
                                    className="row mb-3 details"
                                    key={uuidv4()}
                                  >
                                    {sections?.columns.map((item, index) => {
                                      return (
                                        <div
                                          className={"col-" + colNumber}
                                          key={uuidv4()}
                                        >
                                          {item?.fieldCode == "Company" ? (
                                            <span className="d-flex">
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    <Trans
                                                      i18nKey="company"
                                                      defaults="Company"
                                                    />
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faBuilding}
                                                  />
                                                </span>
                                              </Whisper>
                                              <b className="px-1">
                                                {t(item?.fieldName, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              <span
                                                className="textOverFlow"
                                                title={
                                                  keyData?.company?.text != ""
                                                    ? keyData?.company?.text
                                                    : "NA"
                                                }
                                              >
                                                {keyData?.company?.text !=
                                                "" ? (
                                                  keyData?.company?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="na"
                                                    defaults="NA"
                                                  />
                                                )}
                                              </span>
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode == "Job Title" ? (
                                            <span className="d-flex">
                                             
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    {t(item?.i18nKey, {
                                                      defaultValue:
                                                        item?.fieldName ||
                                                        "Job Title",
                                                    })}
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faUserTie}
                                                  />
                                                </span>
                                              </Whisper>
                                              <b className="px-1">
                                                {t(item?.fieldName, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              <span
                                                className="textOverFlow"
                                                title={
                                                  keyData?.jobTitle?.text != ""
                                                    ? keyData?.jobTitle?.text
                                                    : "NA"
                                                }
                                              >
                                                {keyData?.jobTitle?.text !=
                                                "" ? (
                                                  keyData?.jobTitle?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="na"
                                                    defaults="NA"
                                                  />)
                                                }
                                              </span>
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode ==
                                          "Work Schedule" ? (
                                            <span className="d-flex">
                                             
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    {t(item?.i18nKey, {
                                                      defaultValue:
                                                        item?.fieldName ||
                                                        "Work Schedule",
                                                    })}
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faBusinessTime}
                                                  />
                                                </span>
                                              </Whisper>
                                              <b className="px-1">
                                                {t(item?.fieldName, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              <span
                                                className="textOverFlow"
                                                title={
                                                  keyData?.workSchedule != ""
                                                    ? keyData?.workSchedule
                                                    : "NA"
                                                }
                                              >
                                                {keyData?.workSchedule != "" ? (
                                                  keyData?.workSchedule
                                                ) : (
                                                  <Trans
                                                    i18nKey="na"
                                                    defaults="NA"
                                                  />)
                                                }
                                              </span>
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode ==
                                          "Exp Start Date" ? (
                                            <span className="d-flex">
                                              <Whisper
                                                placement="top"
                                                trigger="hover"
                                                speaker={
                                                  <Tooltip>
                                                    {t(item?.i18nKey, {
                                                      defaultValue:
                                                        item?.fieldName ||
                                                        "Start Date",
                                                    })}
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm pe-1">
                                                  <FontAwesomeIcon
                                                    icon={faCalendarDays}
                                                  />
                                                </span>
                                              </Whisper>

                                              {keyData?.startDate?.standard !=
                                              "" ? (
                                                <>
                                                  {keyData?.startDate
                                                    ?.standard != "" &&
                                                    keyData?.startDate
                                                      ?.standard}
                                                </>
                                              ) : (
                                                <span>
                                                  <Trans
                                                    i18nKey="na"
                                                    defaults="NA"
                                                  />
                                                </span>
                                              )}
                                            </span>
                                          ) : (
                                            <></>
                                          )}

                                          {item?.fieldCode == "Exp End Date" ? (
                                            <span className="d-flex">
                                              <Whisper
                                                placement="top"
                                                trigger="hover"
                                                speaker={
                                                  <Tooltip>
                                                    {t(item?.i18nKey, {
                                                      defaultValue:
                                                        item?.fieldName ||
                                                        "End Date",
                                                    })}
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm pe-1">
                                                  <FontAwesomeIcon
                                                    icon={faCalendarDays}
                                                  />
                                                </span>
                                              </Whisper>

                                              {keyData?.endDate?.standard !=
                                              "" ? (
                                                <>
                                                  {keyData?.endDate?.standard !=
                                                    "" &&
                                                    keyData?.endDate?.standard}
                                                </>
                                              ) : (
                                                <span>
                                                  <Trans
                                                    i18nKey="na"
                                                    defaults="NA"
                                                  />
                                                </span>
                                              )}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode == "Exp Duration" ? (
                                            <span className="d-flex">
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    <Trans
                                                      i18nKey="duration"
                                                      defaults="Duration"
                                                    />
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm pe-1">
                                                  <FontAwesomeIcon
                                                    icon={faBusinessTime}
                                                  />
                                                </span>
                                              </Whisper>
                                              {keyData?.duration?.text != "" ? (
                                                keyData?.duration?.text
                                              ) : keyData?.currentStatus ==
                                                  "Working" &&
                                                keyData?.startDate?.text &&
                                                keyData?.startDate?.text !=
                                                  "" ? (
                                                <DurationCalculation
                                                  startDate={
                                                    keyData?.startDate?.standard
                                                  }
                                                />
                                              ) : (
                                                <Trans
                                                  i18nKey="na"
                                                  defaults="NA"
                                                />
                                              )}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode == "Exp Dates" ? (
                                            <>
                                              <label>
                                                <Whisper
                                                  placement="top"
                                                  trigger="hover"
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "End Date",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faCalendarDays}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </label>
                                              <b className="px-1">
                                                {t(item?.i18nKey, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              <span className="px-1">
                                                <>
                                                  {keyData?.startDate
                                                    ?.standard != "" ? (
                                                    <>
                                                      {keyData?.startDate
                                                        ?.standard != "" &&
                                                        keyData?.startDate
                                                          ?.standard}
                                                    </>
                                                  ) : (
                                                    <span>
                                                      <Trans
                                                        i18nKey="na"
                                                        defaults="NA"
                                                      />
                                                    </span>
                                                  )}
                                                </>
                                              </span>
                                              {"-"}
                                              <span className="px-1">
                                                <>
                                                  {keyData?.startDate
                                                    ?.standard != "" ? (
                                                    <>
                                                      {keyData?.endDate
                                                        ?.standard != "" &&
                                                      keyData?.currentStatus !=
                                                        "Working" ? (
                                                        <>
                                                          {keyData?.endDate
                                                            ?.standard != "" &&
                                                            keyData?.endDate
                                                              ?.standard}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {keyData?.currentStatus !=
                                                          ""
                                                            ? keyData?.currentStatus
                                                            : ""}
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <span>
                                                      <Trans
                                                        i18nKey="na"
                                                        defaults="NA"
                                                      />
                                                    </span>
                                                  )}
                                                </>
                                              </span>

                                              <span className="ps-1">
                                                {keyData?.duration?.text !=
                                                "" ? (
                                                  <>
                                                    <Tag size="lg">
                                                      {" "}
                                                      {keyData?.duration
                                                        ?.text != ""
                                                        ? keyData?.duration
                                                            ?.text
                                                        : ""}
                                                    </Tag>
                                                  </>
                                                ) : (
                                                  <>
                                                    {keyData?.startDate?.text &&
                                                    keyData?.startDate?.text !=
                                                      "" ? (
                                                      <Tag size="lg">
                                                        <DurationCalculation
                                                          startDate={
                                                            keyData?.startDate
                                                              ?.text
                                                          }
                                                        />
                                                      </Tag>
                                                    ) : (
                                                      <>
                                                        {
                                                          <Trans
                                                            i18nKey="na"
                                                            defaults="NA"
                                                          />
                                                        }
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </span>
                                            </>
                                          ) : (
                                            <span></span>
                                          )}
                                          {item?.fieldCode == "Exp Location" ? (
                                            <span className="d-flex">
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    <Trans
                                                      i18nKey="location"
                                                      defaults="Location"
                                                    />
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faLocationDot}
                                                  />
                                                </span>
                                              </Whisper>
                                              <b className="px-1">
                                                {t(item?.fieldName, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              <span
                                                className="textOverFlow"
                                                title={
                                                  keyData?.location?.text != ""
                                                    ? keyData?.location?.text
                                                    : "NA"
                                                }
                                              >
                                                {keyData?.location?.text !=
                                                "" ? (
                                                  keyData?.location?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="na"
                                                    defaults="NA"
                                                  />
                                                )}
                                              </span>
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode == "Exp Status" ? (
                                            <span className="d-flex">
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    {t(item?.i18nKey, {
                                                      defaultValue:
                                                        item?.fieldName ||
                                                        "Status",
                                                    })}
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faIdBadge}
                                                  />
                                                </span>
                                              </Whisper>
                                              <b className="px-1">
                                                {t(item?.fieldName, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              <span>
                                                {keyData?.currentStatus !=
                                                "" ? (
                                                  keyData?.currentStatus ==
                                                  "Working" ? (
                                                    "Yes"
                                                  ) : (
                                                    "No"
                                                  )
                                                ) : (
                                                  <Trans
                                                    i18nKey="na"
                                                    defaults="NA"
                                                  />)
                                                }
                                              </span>
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode ==
                                          "Exp Description" ? (
                                            <>
                                              <ShowMore
                                                description={
                                                  keyData?.description
                                                }
                                                charecterLength={1500}
                                              />
                                            </>
                                          ) : null}

                                          {item?.fieldCode == "Exp Skills" ? (
                                            <span>
                                              {keyData?.skills?.length > 0 ? (
                                                keyData?.skills?.map(
                                                  (keyData, index) => {
                                                    return (
                                                      <>
                                                        <span className="badge rounded-pill bd-primary  bd-skill-badge badge-md my-1 mx-1 ">
                                                          <span className="bd-skill">
                                                            {keyData?.text}
                                                          </span>
                                                          {keyData?.yearsOfExperience &&
                                                          keyData?.yearsOfExperience >
                                                            0 ? (
                                                            <span className="bd-skill">
                                                              {
                                                                keyData?.yearsOfExperience
                                                              }{" "}
                                                              {
                                                                <Trans
                                                                  i18nKey={
                                                                    "years"
                                                                  }
                                                                  defaults="Years"
                                                                />
                                                              }
                                                            </span>
                                                          ) : null}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <Trans
                                                  i18nKey="na"
                                                  defaults="NA"
                                                />
                                              )}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode ==
                                          "Exp Supervisor" ? (
                                            <>
                                              
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="supervisor"
                                                        defaults="Supervisor"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faBuildingUser}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.fieldName, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                <span
                                                  className="textOverFlow"
                                                  title={
                                                    keyData?.supervisor &&
                                                    keyData?.supervisor?.text !=
                                                      ""
                                                      ? keyData?.supervisor
                                                          ?.text
                                                      : "NA"
                                                  }
                                                >
                                                  {keyData?.supervisor &&
                                                  keyData?.supervisor?.text !=
                                                    "" ? (
                                                    keyData?.supervisor?.text
                                                  ) : (
                                                    <Trans
                                                    i18nKey="na"
                                                    defaults="NA"
                                                  />
                                                  )}
                                                </span>
                                              </span>
                                            </>
                                          ) : null}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <span>
                          <Trans
                            i18nKey="noExperience"
                            defaults="No Experience Details"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {open &&(
      <ExperienceSessionEdit
        open={open}
        setOpen={setOpen}
        experienceRowsList={experienceRow}
        resumeId={resumeId}
        companyID={resumeData?.companyID}
        clientID={resumeData?.clientID}
        experienceList={resumeData?.resume?.professionalExperience}
        standardFieldsList={standardFieldsList}
        setUpdateResumeDataData={setUpdateResumeDataData}
        setscrollRef={setscrollRef}
        resumeData={resumeData}
      />
      )}
    </>
  );
};

export default Experience;