import React, { useState, useEffect } from "react";
import IndustryAttribute from "./IndustryAttribute";
import WorkTypeAttribute from "./WorkTypeAttribute";
import LocationAttribute from "./LocationAttribute";
import "../SearchBar.css";
import { Dropdown, Form } from "react-bootstrap";
// import Form from 'react-bootstrap';
// import { useLoaderData } from "react-router-dom";
import { Tag, TagGroup } from "rsuite";
import { Trans,useTranslation } from "react-i18next";
import Login from "../../Login/Login";

/* import searchJsonConfig from "../../../../src/utilities/search.json" */

function SearchAttributes({
  attributeData,
  /*  handleSelectedLocations, */
  getJobs,
  setJobsListData,
  setPage,
  JobsListData,
  setTotal,
  searchBar1Values,
  searchJson,
  setAttributeData,
  companyID,
  selectedOptions,
  setSelectedOptions,
  setworkTypeAttribute,
  workTypeAttribute,
  setSearchvalue,
  industryTypeAttributes,
  setIndustryTypeAttributes,
  setIsSearchMade,
  SearchBodyData,
  Page,
  limit,
  total,
  setShowJobDetails,
  sortOption,
  setSortOption,
}) {
  const { t } = useTranslation();
  // console.log(JobsListData.length, "nnnnnn");
  // console.log(sortOption,"setSortingOption")
  /*  const filtersFromRef=useLoaderData("jobs"); */
  // console.log(JSON.stringify(filtersFromRef?.searchbody?.filter),"filtersFromRef");
  const sortingOptions = ["Relevance", "Date"];
  // const [selectedValue, setSelectedValue] = useState(sortingOptions[0]);

  // console.log(locationtags)

  // console.log(user?.jobs?.data?.attributesAll, "ertry6u78");
  /*  console.log(attributeData, 'attributeData in searchatt123') */

  /*  const industryAttributes=attributeData?.industry; */
  const handleSelectChange = (eventKey) => {
    // const updatedSearchJson = { ...searchJson, sort: [] };
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    let data;
    if (eventKey === "relevance") {
      data = {
        size: 6,
        page: 1,
        skip: 0,
        searchID: parameters.get("searchID"),
        sort: [],
        newSearch: false,
      };
      // updatedSearchJson.sort.push({ type: "relevance", order: "desc" });
      setSortOption([{ type: "relevance", order: "desc" }]);
    } else if (eventKey === "date") {
      data = {
        size: 6,
        page: 1,
        skip: 0,
        searchID: parameters.get("searchID"),
        sort: [
          {
            type: "date",
            order: "desc",
          },
        ],
        newSearch: false,
      };
      setSortOption([{ type: "date", order: "desc" }]);
      // updatedSearchJson.sort.push({ type: "date", order: "desc" });
    }

    // setSelectedValue(eventKey);
    // searchJson.sort = updatedSearchJson.sort;
    setShowJobDetails(false);

    getJobs(data)
      .then((res) => {
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setOpenDrawer(false)
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setSearchvalue(0);

    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };
  // const [industrytags, setIndustrytags] = useState(industryTypeAttributes);
  // const [worktypetags, setworktypetags] = useState(selectedOptions);

  // useEffect(()=>{
  //   const hasRelevanceSort = sortOption.some((option) => option.type.toLowerCase() === "relevance");

  //   const defaultSortType = hasRelevanceSort ? "Relevance" : sortOption.length > 0 ? sortOption[0].type : sortingOptions[0];

  //   setSelectedValue(defaultSortType);
  // },[sortOption])

  // useEffect(() => {
  //   console.log(SearchBodyData?.filter, "cvbhjn");
  //   if (SearchBodyData && SearchBodyData?.filter) {
  //     const industryData = SearchBodyData?.filter?.find(
  //       (item) => item?.type === "industry"
  //     );

  //     if (
  //       industryData &&
  //       industryData?.industry &&
  //       industryData?.industry?.length > 0
  //     ) {
  //       const selectedIndustries = industryData?.industry;
  //       setIndustryTypeAttributes(selectedIndustries);
  //     }
  //     const locationData = SearchBodyData?.filter?.find(
  //       (item) => item?.type === "location"
  //     );

  //     if (
  //       locationData &&
  //       locationData?.location &&
  //       locationData?.location.length > 0
  //     ) {
  //       const selectedLocations = locationData?.location.map((loc) => ({
  //         city: loc.city,
  //         state: loc.state,
  //         postalCode: loc.postalCode,
  //       }));

  //       setSelectedOptions(selectedLocations);
  //     }
  //     const workTypeData = SearchBodyData?.filter?.find(
  //       (item) => item?.type === "workType"
  //     );

  //     if (
  //       workTypeData &&
  //       workTypeData?.workType &&
  //       workTypeData?.workType.length > 0
  //     ) {
  //       const selectedWorkTypes = workTypeData?.workType;
  //       setworkTypeAttribute(selectedWorkTypes);
  //       // setworktypetags(selectedWorkTypes);
  //     }
  //   }
  // }, [SearchBodyData]);
  const worktyperemoveTag = (tag) => {
    const nextTags = workTypeAttribute.filter((item) => item !== tag);
    setworkTypeAttribute(nextTags);
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    let body = {
      size: 6,
      page: 1,
      skip: 0,
      searchID: parameters.get("searchID"),
      filter: [
        {
          type: "workType",
          workType: nextTags,
        },
      ],
      newSearch: false,
    };
    getJobs(body)
      .then((res) => {
        setShowJobDetails(false);
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setSearchvalue(0);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };
  const IndustrytyperemoveTag = (tag) => {
    const nextTags = industryTypeAttributes.filter((item) => item !== tag);
    const filteredItem = industryTypeAttributes.filter((item) => item === tag);
    setIndustryTypeAttributes(nextTags);
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    let body = {
      size: 6,
      page: 1,
      skip: 0,
      searchID: parameters.get("searchID"),
      newSearch: false,
      filter: [
        {
          type: "industry",
          industry: nextTags,
        },
      ],
    };
    if(nextTags?.length === 0){
      setIsSearchMade(false);
    }
    if (parameters.has("industry")) {
      if (parameters.get("industry") == filteredItem) {
        parameters.delete("industry");
        body["search"] = [
          {
            type: "industry",
            industry: [],
          },
        ];
      }
    }
    getJobs(body)
      .then((res) => {
        setShowJobDetails(false);
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setSearchvalue(0);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };
  const locationtyperemoveTag = (tag) => {
    // console.log(selectedOptions, "fgybuhnij");
    const nextTags = selectedOptions.filter((item) => item !== tag);
    // console.log(nextTags);

    setSelectedOptions(nextTags);
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    let body = {
      size: 6,
      page: 1,
      skip: 0,
      searchID: parameters.get("searchID"),
      filter: [
        {
          type: "location",
          location: nextTags,
        },
      ],
      newSearch: false,
    };
    getJobs(body)
      .then((res) => {
        setShowJobDetails(false);
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setSearchvalue(0);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };
  // console.log(selectedOptions, "sdfrgtyuio9p");
  return (
    <>

<div className="container mt-3 ">
        <div className="row align-items-center">
          <div className="col-lg-8 px-0">
            <div className=" d-flex flex-lg-nowrap flex-wrap navbar-container mb-2">
              <div className="m-1  ">
                <IndustryAttribute
                  setShowJobDetails={setShowJobDetails}
                  setSearchvalue={setSearchvalue}
                  industryAttributesList={attributeData?.industry}
                  getJobs={getJobs}
                  setJobsListData={setJobsListData}
                  JobsListData={JobsListData}
                  setPage={setPage}
                  setTotal={setTotal}
                  searchJson={searchJson}
                  setAttributeData={setAttributeData}
                  companyID={companyID}
                  selectedOptions={industryTypeAttributes}
                  setSelectedOptions={setIndustryTypeAttributes}
                  setIsSearchMade={setIsSearchMade}
                  SearchBodyData={SearchBodyData}
                 
                 
                />
              </div>

              <div className="m-1 ">
                <WorkTypeAttribute
                  setShowJobDetails={setShowJobDetails}
                  setSearchvalue={setSearchvalue}
                  workTypeAttribute={attributeData?.workType}
                  getJobs={getJobs}
                  setJobsListData={setJobsListData}
                  setPage={setPage}
                  JobsListData={JobsListData}
                  setTotal={setTotal}
                  searchBar1Values={searchBar1Values}
                  searchJson={searchJson}
                  companyID={companyID}
                  setSelectedOptions={setworkTypeAttribute}
                  selectedOptions={workTypeAttribute}
                  SearchBodyData={SearchBodyData}
                  setAttributeData={setAttributeData}
                 
                />
              </div>

              <div className="m-1 ">
                <LocationAttribute
                  setShowJobDetails={setShowJobDetails}
                  setSearchvalue={setSearchvalue}
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                  locationAttributes={attributeData?.location}
                  getJobs={getJobs}
                  setJobsListData={setJobsListData}
                  setPage={setPage}
                  JobsListData={JobsListData}
                  setTotal={setTotal}
                  searchBar1Values={searchBar1Values}
                  searchJson={searchJson}
                  companyID={companyID}
                  SearchBodyData={SearchBodyData}
                  setAttributeData={setAttributeData}
                  
                />
              </div>

              {/*  <div className="m-1 ">
                <SalaryAttribute  salaryAttribute={attributeData?.salary}/>
              </div> */}
            </div>
          </div>

          <div className="col-lg-4 d-flex justify-content-end align-middle border-0 sortPosition align-items-center">
          <div className="d-lg-block d-none w-100">
          <b>
              {t("showing", { defaultValue: "Showing" })} {(Page - 1) * limit + 1} -
                {limit > total ? (
                  <span className="ps-1 pe-1"> {total} </span>
                ) : JobsListData.length < limit ? (
                  <span className="ps-1 pe-1">{total}</span>
                ) : (
                  <span className="ps-1 pe-1">{Page * limit}</span>
                )}
                 <Trans i18nKey="of" defaults="of" /> <span className="ps-1 pe-1">{total}</span><Trans i18nKey="jobs" defaults="Jobs" />
              </b>
            </div>
            {/* <Dropdown onSelect={handleSelectChange}>
              <Dropdown.Toggle variant="white" id="dropdown-sort">
                Sort: {selectedValue}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sortingOptions.map((option) => (
                  <Dropdown.Item
                    key={option}
                    eventKey={option}
                    className="custom-dropdown-item "
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}

            <div className="ps-3 d-flex align-items-center w-100  w-lg-none">
              <label className="me-2">
              <b className="btnStyle"><Trans i18nKey="sort" defaults="Sort" />:</b>
              </label>
              <Form.Select
                role="button"
                size="sm"
                onChange={(e) => {
                  // console.log(e.target.value);
                  handleSelectChange(e.target.value);
                }}
                value={
                  sortOption?.length > 0
                    ? sortOption[0].type
                    : sortingOptions[0]
                }
              >
                <option value={"relevance"}><Trans i18nKey="relevance" defaults="Relevance" /></option>
                <option value={"date"}><Trans i18nKey="date" defaults="Date" /></option>
              </Form.Select>
            </div>
          </div>
        </div>
        <div className="m-2">
          <TagGroup>
            {industryTypeAttributes?.map((x, index) => {
              return (
                <Tag
                  closable
                  className="Attributtestags"
                  key={index}
                  onClose={() => IndustrytyperemoveTag(x)}
                >
                  {x}
                </Tag>
              );
            })}
            {workTypeAttribute?.map((x, index) => {
              return (
                <Tag
                  closable
                  className="Attributtestags"
                  key={index}
                  onClose={() => worktyperemoveTag(x)}
                >
                  {x}
                </Tag>
              );
            })}
            {selectedOptions?.map((option, index) => {
              return (
                <Tag
                  closable
                  className="Attributtestags"
                  key={index}
                  onClose={() => locationtyperemoveTag(option)}
                >
                  {option?.city?.charAt(0).toUpperCase() +
                    option?.city?.slice(1)}{" "}
                  -{" "}
                  {option?.state?.charAt(0).toUpperCase() +
                    option?.state?.slice(1)}
                  {/* {option?.city} - {option?.state} */}
                </Tag>
              );
            })}
          </TagGroup>
        </div>
      </div>
    </>
  );
}

export default SearchAttributes;
